import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/x24/Time.svg'
import _imports_1 from '@/assets/images/icons/x24/Video-Play-Playlist.svg'
import _imports_2 from '@/assets/images/icons/x24/Badge.svg'


const _hoisted_1 = { class: "course-card-high row mb-4" }
const _hoisted_2 = { class: "col-4 h-100 ps-0" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col-8 h-100 d-flex flex-column justify-content-between pt-4 pb-3 pe-4" }
const _hoisted_5 = { class: "title-course-card-high" }
const _hoisted_6 = { class: "description-course-card-high" }
const _hoisted_7 = { class: "row justify-content-between mt-2" }
const _hoisted_8 = { class: "col-7 d-flex justify-content-start" }
const _hoisted_9 = { class: "course-info-details d-flex align-items-center text-nowrap me-5" }
const _hoisted_10 = { class: "course-info-details d-flex align-items-center text-nowrap me-5" }
const _hoisted_11 = { class: "w-100 d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "img-course-card-high",
        src: _ctx.imageURI + _ctx.course?.s3_thumbnail_name,
        alt: "Course image"
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", null, [
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.course?.name), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.course?.description), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _cache[0] || (_cache[0] = _createElementVNode("img", {
                class: "me-1",
                src: _imports_0,
                alt: ""
              }, null, -1 /* HOISTED */)),
              _createTextVNode(" " + _toDisplayString(_ctx.course?.duration), 1 /* TEXT */)
            ]),
            _createElementVNode("span", _hoisted_10, [
              _cache[1] || (_cache[1] = _createElementVNode("img", {
                class: "me-1",
                src: _imports_1,
                alt: ""
              }, null, -1 /* HOISTED */)),
              _createTextVNode(" " + _toDisplayString((_ctx.course?.lessons ? _ctx.course?.lessons?.length : _ctx.course?.lesson_count) + " Lezioni"), 1 /* TEXT */)
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "course-info-details d-flex align-items-center text-nowrap me-5" }, [
              _createElementVNode("img", {
                class: "me-1",
                src: _imports_2,
                alt: ""
              }),
              _createTextVNode(" Attestato finale ")
            ], -1 /* HOISTED */))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_router_link, {
          to: '/course/' + _ctx.course?.id,
          class: "btn btn-platform px-4 d-flex align-items-center"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Vai al corso")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"])
      ])
    ])
  ]))
}