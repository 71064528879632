import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/icons/x56/Play.svg'


const _hoisted_1 = { class: "container-fluid text-center" }
const _hoisted_2 = { class: "row my-3 gy-4" }
const _hoisted_3 = { class: "row card-wrapper" }
const _hoisted_4 = { class: "col-4 px-0 position-relative" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "col-8 d-flex flex-column justify-content-between align-items-start py-3 pe-4" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-description mt-2 mb-0" }
const _hoisted_9 = {
  key: 0,
  class: "w-100 d-flex flex-column align-items-start"
}
const _hoisted_10 = { class: "card-video-count m-1" }
const _hoisted_11 = {
  key: 1,
  class: "w-100 d-flex flex-column align-items-start"
}
const _hoisted_12 = { class: "card-video-count m-1 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_ProgressBarPurple = _resolveComponent("ProgressBarPurple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
        return (_openBlock(), _createElementBlock("div", {
          key: course.id,
          class: "col-12 col-lg-6 px-4"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "img-fluid img-card",
                src: _ctx.imageURI + course.s3_thumbnail_name,
                alt: "course image"
              }, null, 8 /* PROPS */, _hoisted_5),
              _createVNode(_component_router_link, {
                to: '/course/' + course.id,
                class: "card-play-button"
              }, {
                default: _withCtx(() => [...(_cache[0] || (_cache[0] = [
                  _createElementVNode("img", {
                    class: "img-fluid img-card-play",
                    src: _imports_0,
                    alt: ""
                  }, null, -1 /* HOISTED */)
                ]))]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_7, _toDisplayString(course.name), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_8, _toDisplayString(course.description), 1 /* TEXT */)
              ]),
              (_ctx.currentUserInfo.getRole != 'high-student')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_ProgressBar, {
                      percentage: _ctx.getPercentage(course),
                      large: false
                    }, null, 8 /* PROPS */, ["percentage"]),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(course.video_count_completed + "/" + course.video_count), 1 /* TEXT */)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_ProgressBarPurple, {
                      percentage: _ctx.getPercentage(course),
                      explicit: false
                    }, null, 8 /* PROPS */, ["percentage"]),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.getPercentage(course) + "%"), 1 /* TEXT */)
                  ]))
            ])
          ])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}