import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "459",
  height: "487",
  viewBox: "0 0 459 487",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = { "clip-path": "url(#clip0_3275_39513)" }
const _hoisted_3 = { mask: "url(#mask0_3275_39513)" }
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]
const _hoisted_6 = ["fill"]
const _hoisted_7 = ["fill"]
const _hoisted_8 = ["fill"]
const _hoisted_9 = { "clip-path": "url(#clip1_3275_39513)" }
const _hoisted_10 = ["fill"]
const _hoisted_11 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("mask", {
        id: "mask0_3275_39513",
        style: {"mask-type":"alpha"},
        maskUnits: "userSpaceOnUse",
        x: "55",
        y: "62",
        width: "349",
        height: "425"
      }, [
        _createElementVNode("path", {
          d: "M229.496 62.5908C283.269 62.5908 326.86 106.182 326.86 159.954L326.861 170.675C336.728 172.331 344.246 180.912 344.246 191.25V215.591C344.246 226.114 336.456 234.817 326.328 236.248C322.736 270.682 301.194 299.779 271.225 314.016L271.224 345.989H278.178C347.314 345.989 403.36 402.034 403.36 471.17V486.818H55.6328V471.17C55.6328 402.034 111.679 345.989 180.815 345.989H187.769L187.769 314.017C157.8 299.78 136.257 270.683 132.663 236.248C122.537 234.817 114.746 226.114 114.746 215.591V191.25C114.746 180.912 122.265 172.33 132.133 170.675L132.133 159.954C132.133 106.182 175.724 62.5908 229.496 62.5908Z",
          fill: "#D08B5B"
        })
      ], -1 /* HOISTED */)),
      _createElementVNode("g", _hoisted_3, [
        _createElementVNode("path", {
          d: "M229.496 62.5908C283.269 62.5908 326.86 106.182 326.86 159.954L326.861 170.675C336.728 172.331 344.246 180.912 344.246 191.25V215.591C344.246 226.114 336.456 234.817 326.328 236.248C322.736 270.682 301.194 299.779 271.225 314.016L271.224 345.989H278.178C347.314 345.989 403.36 402.034 403.36 471.17V486.818H55.6328V471.17C55.6328 402.034 111.679 345.989 180.815 345.989H187.769L187.769 314.017C157.8 299.78 136.257 270.683 132.663 236.248C122.537 234.817 114.746 226.114 114.746 215.591V191.25C114.746 180.912 122.265 172.33 132.133 170.675L132.133 159.954C132.133 106.182 175.724 62.5908 229.496 62.5908Z",
          fill: _ctx.body
        }, null, 8 /* PROPS */, _hoisted_4),
        _cache[0] || (_cache[0] = _createElementVNode("path", {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M132.133 226.023C132.133 279.795 175.724 323.386 229.496 323.386C283.269 323.386 326.86 279.795 326.86 226.023V239.932C326.86 293.704 283.269 337.295 229.496 337.295C175.724 337.295 132.133 293.704 132.133 239.932V226.023Z",
          fill: "black",
          "fill-opacity": "0.1"
        }, null, -1 /* HOISTED */))
      ]),
      _cache[2] || (_cache[2] = _createStaticVNode("<mask id=\"mask1_3275_39513\" style=\"mask-type:alpha;\" maskUnits=\"userSpaceOnUse\" x=\"196\" y=\"255\" width=\"67\" height=\"34\"><path d=\"M196.666 259.279C198.505 275.781 212.502 288.613 229.495 288.613C246.541 288.613 260.571 275.702 262.341 259.126C262.483 257.792 260.993 255.579 259.126 255.579C233.231 255.579 213.392 255.579 200.089 255.579C198.211 255.579 196.482 257.628 196.666 259.279Z\" fill=\"white\"></path></mask><g mask=\"url(#mask1_3275_39513)\"><path d=\"M196.666 259.279C198.505 275.781 212.502 288.613 229.495 288.613C246.541 288.613 260.571 275.702 262.341 259.126C262.483 257.792 260.993 255.579 259.126 255.579C233.231 255.579 213.392 255.579 200.089 255.579C198.211 255.579 196.482 257.628 196.666 259.279Z\" fill=\"black\" fill-opacity=\"0.7\"></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M212.111 236.454H248.623C253.424 236.454 257.316 240.346 257.316 245.147V255.579C257.316 260.38 253.424 264.272 248.623 264.272H212.111C207.31 264.272 203.418 260.38 203.418 255.579V245.147C203.418 240.346 207.31 236.454 212.111 236.454Z\" fill=\"white\"></path><path d=\"M220.809 312.954C231.371 312.954 239.934 304.391 239.934 293.829C239.934 283.266 231.371 274.704 220.809 274.704C210.246 274.704 201.684 283.266 201.684 293.829C201.684 304.391 210.246 312.954 220.809 312.954Z\" fill=\"#FF4F6D\"></path><path d=\"M238.195 312.954C248.758 312.954 257.32 304.391 257.32 293.829C257.32 283.266 248.758 274.704 238.195 274.704C227.633 274.704 219.07 283.266 219.07 293.829C219.07 304.391 227.633 312.954 238.195 312.954Z\" fill=\"#FF4F6D\"></path></g><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M208.637 226.0s22C208.637 233.704 217.978 239.932 229.5 239.932C241.023 239.932 250.364 233.704 250.364 226.022\" fill=\"black\" fill-opacity=\"0.16\"></path>", 3)),
      _createElementVNode("path", {
        d: "M184.291 205.159C190.053 205.159 194.723 200.488 194.723 194.727C194.723 188.965 190.053 184.295 184.291 184.295C178.53 184.295 173.859 188.965 173.859 194.727C173.859 200.488 178.53 205.159 184.291 205.159Z",
        fill: _ctx.eyes
      }, null, 8 /* PROPS */, _hoisted_5),
      _createElementVNode("path", {
        d: "M274.701 205.159C280.463 205.159 285.133 200.488 285.133 194.727C285.133 188.965 280.463 184.295 274.701 184.295C268.94 184.295 264.27 188.965 264.27 194.727C264.27 200.488 268.94 205.159 274.701 205.159Z",
        fill: _ctx.eyes
      }, null, 8 /* PROPS */, _hoisted_6),
      _createElementVNode("path", {
        d: "M159.308 172.4C166.115 162.823 184.775 157.452 200.849 161.399C202.714 161.857 204.597 160.717 205.055 158.851C205.513 156.986 204.372 155.103 202.507 154.645C183.84 150.061 162.232 156.28 153.639 168.371C152.527 169.937 152.894 172.108 154.459 173.22C156.025 174.333 158.196 173.966 159.308 172.4Z",
        fill: _ctx.eyeBrow
      }, null, 8 /* PROPS */, _hoisted_7),
      _createElementVNode("path", {
        d: "M299.684 172.4C292.878 162.823 274.217 157.452 258.143 161.399C256.278 161.857 254.395 160.717 253.937 158.851C253.479 156.986 254.62 155.103 256.485 154.645C275.152 150.061 296.76 156.28 305.353 168.371C306.465 169.937 306.098 172.108 304.533 173.22C302.967 174.333 300.797 173.966 299.684 172.4Z",
        fill: _ctx.eyeBrow
      }, null, 8 /* PROPS */, _hoisted_8),
      _createElementVNode("g", _hoisted_9, [
        _createElementVNode("path", {
          d: "M416.82 474.846V491.116H53.1836V474.846C53.1836 409.549 100.991 355.418 163.512 345.545C165.155 354.765 169.76 363.524 177.249 370.277L230.131 417.966C232.899 420.462 237.105 420.462 239.872 417.966L292.754 370.277C293.528 369.58 294.277 368.854 294.999 368.103C301.208 361.639 305.032 353.758 306.491 345.549C369.014 355.419 416.82 409.55 416.82 474.846Z",
          fill: _ctx.tshirt
        }, null, 8 /* PROPS */, _hoisted_10)
      ]),
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M302.001 53C267.878 27.6978 210.386 20.3972 190.491 50.4666C175.663 43.881 153.683 49.8826 137.429 65C121.031 81.1411 107.942 116.771 115.732 145.926C115.551 148.093 115.46 150.286 115.46 152.499L115.46 189.957C116.971 181.431 123.733 174.72 132.285 173.285L132.286 173C142.635 136.078 173.041 119.134 191.554 102.328C220.507 134.726 310.875 134.838 341.429 198.714V215.857C341.429 226.175 333.833 234.719 323.928 236.201C320.94 270.911 299.482 300.342 269.429 314.619L269.429 315.286C264.406 390.466 280.815 484.653 387.715 455.857C364.328 442.171 351.977 354.339 351.712 300.349L351.715 149.079C351.715 143.03 351.034 137.14 349.744 131.482C344.513 97.1204 324.675 69.8216 302.001 53ZM115.46 219.472L115.46 221.806C115.46 248.94 109.019 275.687 96.6665 299.846L50.4691 390.202C38.5317 413.55 42.0371 440.729 57.219 460.093C62.0572 396.354 115.308 346.143 180.286 346.143L187.143 346.141L187.142 314.618C157.09 300.341 135.632 270.91 132.644 236.201C123.923 234.896 116.992 228.116 115.46 219.472Z",
        fill: _ctx.hair
      }, null, 8 /* PROPS */, _hoisted_11),
      _cache[3] || (_cache[3] = _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M189.84 102.328C171.327 119.134 140.921 136.078 130.572 173L130.642 172.755C141.056 140.881 171.363 129.623 189.835 115.071C218.797 143.183 309.162 143.282 339.715 198.714C309.49 135.526 220.733 134.735 190.791 103.366L189.84 102.328Z",
        fill: "black",
        "fill-opacity": "0.16"
      }, null, -1 /* HOISTED */))
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "clip0_3275_39513" }, [
        _createElementVNode("rect", {
          width: "459",
          height: "486.818",
          fill: "white"
        })
      ]),
      _createElementVNode("clipPath", { id: "clip1_3275_39513" }, [
        _createElementVNode("rect", {
          width: "480",
          height: "200",
          fill: "white",
          transform: "translate(-5 291)"
        })
      ])
    ], -1 /* HOISTED */))
  ]))
}