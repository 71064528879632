<template>
  <ul class="dropdown-menu">
    <div class="d-flex flex-column align-items-center my-3">
      <div :class="userStore.getRole == 'high-student' ? 'd-flex flex-row align-items-center':'d-flex flex-column align-items-center personal-width'">
        <img
        :src="userStore.currentLevel?.imageUnlock"
        class="img-fluid img-current-level"
        :class="userStore.getRole == 'high-student' ? 'img-current-level-high' : 'img-current-level'"
        alt=""
        />
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style="width: 80%"
        >
          <h3 class="dropdown-items-title mt-3" :class="userStore.getRole == 'high-student'? '':'text-center'">
            {{ userStore.currentLevel?.level ?? "" }}
          </h3>

          <div class="w-100 d-flex align-items-end" :class="userStore.getRole == 'high-student'? '':'text-center'">
            <div v-if="userStore.getRole == 'high-student'" class="personal-width">
              <ProgressBarPurple
                class="d-flex flex-column mt-2"
                :achieved=userStore.currentUser.level 
                :total=userStore.currentLevel?.points
                :percentage="completionPercentage"
                :explicit="false"
                :center="false"
              />
            </div>
    
            <div v-else class="d-flex align-items-end mt-3 mb-1 w-100">
              <img
                src="@/assets/images/icons/Crown-Lesson-Yellow.svg"
                class="crown img-fluid"
                style="height: 20px"
                alt=""
              />
              <div class="progress w-100">
                <div
                  class="progress-bar"
                  :style="{ width: completionPercentage + '%' }"
                  role="progressbar"
                  aria-label="Level completion percentage"
                  :aria-valuenow="completionPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <span v-if="userStore.getRole != 'high-student'" class="user-level ms-2">{{
              user.level /* + "/" + userStore.currentLevel.points */
            }}</span>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column mt-3">
        <router-link
          :to="{ name: 'profile' }"
          class="btn dropdown-items-btn ps-0"
        >
          <img
            src="@/assets/images/icons/x56/User,Profile.svg"
            class="img-fluid me-2"
            style="width: 42px"
            alt=""
          />
          VAI AL PROFILO
        </router-link>

        <button
          @click="logout()"
          class="btn dropdown-items-btn dropdown-logout ps-0 mt-3"
        >
          <img
            src="@/assets/images/icons/x56/Logout.svg"
            class="img-fluid"
            style="width: 42px"
            alt=""
          />
          ESCI
        </button>
      </div>
    </div>
  </ul>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { useUserInfoStore } from "@/store";

import { useRoute } from "vue-router";
import ProgressBarPurple from "../ProgressBarPurple.vue";

export default defineComponent({
  name: "HeaderDropdown",
  props: {
    elementari: Boolean,
  },
  setup(props) {
    const userStore = useUserInfoStore();
    console.log("userStore", userStore);
    const route = useRoute();
    const currentRoute = computed(() => route.name);
    const user = computed(() => userStore.currentUser);
    let steps = userStore.getUserSteps;
    const completionPercentage = computed(() => {
      const userPoints = user.value.level;
      const nextLevelIndex = steps.indexOf(userStore.currentLevel) + 1;
      if (steps[nextLevelIndex]) {
        const requiredPoints =
          steps[nextLevelIndex].points - userStore.currentLevel.points;
        const currentPercentage = (userPoints * 100) / requiredPoints;
        return Math.floor(currentPercentage);
      } else {
        return (userPoints * 100) / 2000;
      }
    });
    const logout = () => {
      userStore.logout();
    };
    return {
      props,
      user,
      userStore,
      currentRoute,
      completionPercentage,
      logout,
    };
  },
  components: { ProgressBarPurple },
});
</script>

<style lang="scss" scoped>
.dropdown-items-btn {
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #7b8794;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0;
}

.dropdown-items-btn:hover {
  color: #7b8794;
}

.dropdown-logout {
  border-top: 1.5px solid #a4bbfc;
  padding-top: 16px;
  gap: 16px;
}

.dropdown-logout:hover {
  border-top: 1.5px solid #a4bbfc;
  padding-top: 16px;
}

.progress {
  height: 10px;
  width: 160px;
  margin-bottom: 2px;
}

.progress-bar {
  background-color: #e8b103;
}

.dropdown-items-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #1f2933;
}

.text-center{
  text-align: center;
}

.personal-width{
  width: 90%;
}

.dropdown-toggle.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border-color: white !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow: 0px 1px 8px rgba(123, 135, 148, 0.12),
    0px 8px 16px rgba(123, 135, 148, 0.08);
  border-radius: 15px;
  right: 0 !important;
  left: auto !important;
  width: 320px;
}

a {
  text-decoration: none;
}

h2 {
  margin: 0;
  padding: 0;
}

.img-current-level {
  //width: 85px;
  height: 128px;
}

.img-current-level-high {
  height: 110px;
  margin-left: 17px;
  margin-right: 23px;
}

.user-level {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: right;
  color: rgba(123, 135, 148, 1);
  margin-bottom: 2px;
}
</style>
