<template>

  <div class="row mb-4">
    <div class="col">
      <slot :currentPage="currentPage"></slot>
    </div>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-center">
      <button class="btn btn-pagination" @click="prevPage" :disabled="currentPage === 1">
        &lt;
      </button>
      <button class="btn btn-pagination" @click="nextPage" :disabled="currentPage === props.totalPages">
        &gt;
      </button>
    </div>
  </div>

</template>

<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps({
  totalPages: {
    type: Number,
    required: true
  }
})

const currentPage = ref(1)

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--
  }
}

const nextPage = () => {
  if (currentPage.value < props.totalPages) {
    currentPage.value++
  }
}
</script>

<style lang="scss" scoped>
.btn-pagination {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 0.9375px solid #cbd2d9;
  font-weight: 500;
  font-size: 18.75px;
  line-height: 120%;
  color: #cbd2d9;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-pagination.active {
  background: $platform-bg;
  color: $platform-text-color-2;
}
</style>
