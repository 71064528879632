<template>
  <form @submit.prevent="signUp" class="form-signup">

    <div class="row mb-3">
      <div class="position-relative col-12 col-md-6 mb-3 md-0">
        <label for="InputName" class="form-label ms-3">Nome</label>
        <input type="text" v-model="name" required class="form-control px-5" :class="[
          name != '' ? 'active' : '',
          error && error?.message ? 'is-invalid' : '',
        ]" id="InputName" placeholder="Inserisci il nome" aria-describedby="nameHelp" />
        <img class="position-absolute" style="top: 45px; left: 30px" src="@/assets/images/icons/Studente.svg" alt="" />
      </div>

      <div class="position-relative col-12 col-md-6">
        <label for="InputSurname" class="form-label ms-3">Cognome</label>
        <input type="text" v-model="surname" required class="form-control px-5" :class="[
          surname != '' ? 'active' : '',
          error && error?.message ? 'is-invalid' : '',
        ]" id="Inputsurname" placeholder="Inserisci il cognome" aria-describedby="surnameHelp" />
        <img class="position-absolute" style="top: 45px; left: 30px" src="@/assets/images/icons/Studente.svg" alt="" />
      </div>

    </div>

    <div class="position-relative mb-3">
      <label for="" class="form-label ms-3">Data di nascita</label>
      <DatePickerMask v-model="birthDate" @isAdultChanged="handleIsAdultChanged" />
      <img class="position-absolute" style="top: 45px; left: 20px" src="@/assets/images/icons/Calendar.svg" alt="" />
    </div>

    <div class="mb-3 form-check" v-if="isAdult === false">
      <input type="checkbox" class="form-check-input" v-model="parentConfirmation" id="Check2" />
      <label class="form-check-label" for="Check2">Confermo che un genitore è a conoscenza della mia iscrizione</label>
    </div>

    <div class="mb-3 position-relative">
      <label for="InputEmail1" class="form-label ms-3">Email</label>
      <input type="email" v-model="email" required class="form-control px-5" :class="[
        email != '' ? 'active' : '',
        error && error?.message ? 'is-invalid' : '',
      ]" id="InputEmail1" placeholder="Inserisci la mail" aria-describedby="emailHelp" />
      <img class="position-absolute" style="top: 45px; left: 20px" src="@/assets/images/icons/Mail.svg" alt="" />
    </div>

    <div v-if="!isEmailValid" class="login-error-wrapper mb-2 p-1 d-flex align-items-center">
      <p class="login-error-text p-0 m-0">L'email deve essere in un formato valido</p>
    </div>

    <div class="row mb-3">

      <div class="position-relative col-12 col-md-6 mb-3 md-0">
        <label for="InputPassword1" class="form-label ms-3">Password</label>
        <input :type="showPassword ? 'text' : 'password'" v-model="password" required class="form-control px-5" :class="[
          password != '' ? 'active' : '',
          error && error?.message ? 'is-invalid' : '',
        ]" placeholder="Inserisci la password" id="InputPassword1" />
        <img class="position-absolute" style="top: 45px; left: 20px" src="@/assets/images/icons/Kye.svg" alt="" />
      </div>

      <div class="position-relative col-12 col-md-6">
        <label for="InputPassword2" class="form-label ms-3">Conferma password</label>
        <input :type="showPassword ? 'text' : 'password'" v-model="confirmPassword" required class="form-control px-5"
          :class="[
            confirmPassword != '' ? 'active' : '',
            error && error?.message ? 'is-invalid' : '',
          ]" placeholder="Conferma la password" id="InputPassword2" />
        <img class="position-absolute" style="top: 45px; left: 20px" src="@/assets/images/icons/Kye.svg" alt="" />
      </div>

    </div>

    <div class="mb-3">
      <div v-if="!isPasswordLenghtValid" class="login-error-wrapper mb-2 p-1 d-flex align-items-center">
        <p class="login-error-text p-0 m-0">La password deve essere almeno di 8 caratteri</p>
      </div>

      <div v-if="!passwordsMatch" class="login-error-wrapper p-1 d-flex align-items-center">
        <p class="login-error-text p-0 m-0">Password e conferma password devono essere uguali</p>
      </div>
    </div>

    <div class="mb-3">
      <label for="InputPassword2" class="form-label ms-3">Sono qui perché sono...</label>
      <select class="form-select form-control" v-model="additionalInfo" aria-label="Default select example">
        <option disabled value="">Seleziona un'opzione</option>
        <option value="student">Studente</option>
        <option value="parent">Genitore</option>
        <option value="teacher">Insegnante</option>
        <option value="school_principal">Dirigente scolastico</option>
        <option value="financier">Finanziatore</option>
        <option value="other">Altro</option>
      </select>
    </div>

    <div class="mb-3 form-check">
      <input type="checkbox" class="form-check-input" v-model="showPassword" id="Check1" />
      <label class="form-check-label" for="Check1">Mostra password</label>
    </div>



    <button ref="submitButton" type="submit" class="form-submit-button btn w-100 mt-4" :disabled="isButtonDisabled">
      REGISTRATI
    </button>
  </form>

  <GenericModal :text1="'Attenzione!'" :text2="'I dati inseriti non sono corretti. Controlla e riprova'"
    :button_text="'OK, ho capito'" ref="modal" />
  <GenericModal :text1="'Registrazione completata'"
    :text2="`Hai completato la registrazione ${name.toUpperCase()}, prosegui alla selezione dei corsi`"
    :button_text="'Prosegui'" ref="modalConfirmSignup" @modal-close="proceed" />
</template>

<script setup>

import { ref, computed, defineEmits } from 'vue';
import { useUserInfoStore } from "@/store";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";

const router = useRouter();

const store = useUserInfoStore();

import GenericModal from '@/components/modals/newModal/GenericModal.vue'
import DatePickerMask from '@/components/datepicker/DatepickerMask.vue'

const emit = defineEmits(['form-signup-loading'])

const name = ref("");
const surname = ref("");
const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const showPassword = ref(false);
const submitButton = ref(null);
const birthDate = ref('');
const isAdult = ref(null)
const parentConfirmation = ref(false)
const additionalInfo = ref('')

const modal = ref()
const modalConfirmSignup = ref()


const handleIsAdultChanged = (val) => isAdult.value = val

const isPasswordLenghtValid = computed(() => {
  if (password.value.length < 8 && password.value.length > 1) return false
  return true
})

const isEmailValid = computed(() => {
  // regex validazione dell'email
  if (email.value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.value);
  } else {
    return true
  }

});

const passwordsMatch = computed(() => {
  return password.value === confirmPassword.value;
});

const isButtonDisabled = computed(() => {
  // Verifica condizioni base
  const baseConditions =
    name.value === "" ||
    surname.value === "" ||
    email.value === "" ||
    !isEmailValid.value ||
    password.value === "" ||
    birthDate.value.length < 10 ||
    !passwordsMatch.value;

  // Verifica condizione per gli adulti
  const adultCondition =
    isAdult.value === false && !parentConfirmation.value;

  // Il pulsante è disabilitato se una delle condizioni base è vera
  // O se l'utente è adulto ma non ha la conferma del genitore
  return baseConditions || adultCondition;
});

const signUp = async () => {

  emit('form-signup-loading', true)

  const payload = {
    data: {
      user: {
        name: name.value,
        surname: surname.value,
        email: email.value,
        password: password.value,
        privacy_of_minors: parentConfirmation.value
      }
    }
  }

  // add additional_info key to payload only if value exist
  if (additionalInfo.value) payload.data.user.additional_info = additionalInfo.value

  await ApiService.post("/api/registration", payload)
    .then(async ({ data }) => {
      if (data) {
        let result = await store.login(email.value, password.value, false);
        emit('form-signup-loading', false)
        if (result) {
          modalConfirmSignup.value.openModal()
        }


      }
    })
    .catch(({ response }) => {
      console.log(response)
      emit('form-signup-loading', false)
      modal.value.openModal()
    })
}

const proceed = () => router.push({ name: 'role-select' })
const error = computed(() => store.getErrors);

</script>


<style lang="scss" scoped>
.form-signup {
  max-width: 75%;
  margin-inline: auto;

  @media screen and (max-width: 992px) {
    max-width: 100%;
  }
}

.subtitle {
  align-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  color: #242424;
}

.login-header-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.01em;
}

.login-header-p {
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #242424;
}

.form-control {
  border: 1px solid var(--bs-blue) !important;
}

.form-control.active {
  border: 3px solid $platform-bg !important;
  border-radius: 49px !important;
}

.form-control:focus-visible {
  border: 3px solid $platform-bg !important;
  box-shadow: 0px 0px 0px 4px rgba(3, 40, 142, 0.3) !important;
  border-radius: 49px !important;
}

.form-control.is-invalid {
  border: 3px solid #ff5252 !important;
  box-shadow: 0px 0px 0px 4px #e8533a !important;
  border-radius: 49px !important;
}

.form-submit-button {
  color: $platform-text-color-2;
  background-color: $platform-bg;
  border-radius: 50px;
  padding: 20px 24px 20px 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
}

.form-submit-button:disabled {
  background-color: #e4e7eb;
  color: #9aa5b1;
}

.login-error-wrapper {
  background: #fde3e4;
  border-radius: 0px 5px 5px 0px;
  border-left: 3px solid #ff5252 !important;
  border-radius: 5px 5px 5px 5px;
}

.login-error-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #1f2933;
}

.privacy-text {
  font-size: 12px;
  text-justify: inter-word;
}

.accept-newsletter {
  font-size: 14px;
  text-justify: inter-word;
}

select.form-select {
  padding-left: 1rem!important;
}



.dp__outer_menu_wrap.dp--menu-wrapper {
  // @apply !shadow-xl;
}

/* modals */
.modal-layover {
  // @apply overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full bg-black/[.4] h-full grid justify-items-center lg:p-5;
}

</style>

<style lang="css">
.dp__input {
  background-color: transparent !important;
  border: none !important;
}

.dp__input_icons {
  height: 24px !important;
  width: 24px !important;
  left: -5px !important;

}

.dp__input_icon_pad {
  padding-left: 2.3rem !important;
}
</style>
