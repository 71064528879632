import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/logos/logo.svg'
import _imports_1 from '@/assets/images/icons/x32/Dashboard-active.svg'
import _imports_2 from '@/assets/images/icons/x32/Dashboard.svg'
import _imports_3 from '@/assets/images/icons/x32/Courses-active.svg'
import _imports_4 from '@/assets/images/icons/x32/Corsi.svg'
import _imports_5 from '@/assets/images/icons/x32/Progress-active.svg'
import _imports_6 from '@/assets/images/icons/x32/Progress.svg'
import _imports_7 from '@/assets/images/icons/x32/Studenti-active.svg'
import _imports_8 from '@/assets/images/icons/x32/Studenti.svg'


const _hoisted_1 = {
  id: "aside",
  class: "position-fixed"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "d-flex justify-content-center mt-4" }
const _hoisted_4 = { class: "mt-5" }
const _hoisted_5 = { class: "list-group list-group-flush" }
const _hoisted_6 = {
  key: 0,
  class: "img-fluid",
  src: _imports_1,
  alt: "Dashboard"
}
const _hoisted_7 = {
  key: 1,
  class: "img-fluid",
  src: _imports_2,
  alt: "Dashboard"
}
const _hoisted_8 = {
  key: 0,
  class: "img-fluid",
  src: _imports_3,
  alt: "Courses"
}
const _hoisted_9 = {
  key: 1,
  class: "img-fluid",
  src: _imports_4,
  alt: "Courses"
}
const _hoisted_10 = {
  key: 0,
  class: "img-fluid",
  src: _imports_5,
  alt: "Missions"
}
const _hoisted_11 = {
  key: 1,
  class: "img-fluid",
  src: _imports_6,
  alt: "Missions"
}
const _hoisted_12 = {
  key: 0,
  class: "img-fluid",
  src: _imports_7,
  alt: "Class"
}
const _hoisted_13 = {
  key: 1,
  class: "img-fluid",
  src: _imports_8,
  alt: "Class"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: { name: 'dashboard' } }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1 /* HOISTED */)
          ])),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: { name: 'dashboard' },
            class: _normalizeClass(["list-group-item mt-2", _ctx.path == 'dashboard' ? 'active' : ''])
          }, {
            default: _withCtx(() => [
              (_ctx.path == 'dashboard')
                ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                : (_openBlock(), _createElementBlock("img", _hoisted_7)),
              _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "list-group-item-title" }, "Dashboard", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          _createVNode(_component_router_link, {
            to: { name: 'courses' },
            class: _normalizeClass(["list-group-item mt-2", _ctx.path == 'courses' || _ctx.path == 'course-page' ? 'active' : ''])
          }, {
            default: _withCtx(() => [
              (_ctx.path == 'courses' || _ctx.path == 'course-page')
                ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                : (_openBlock(), _createElementBlock("img", _hoisted_9)),
              _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "list-group-item-title" }, "Corsi", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          _createVNode(_component_router_link, {
            to: { name: 'missions' },
            class: _normalizeClass(["list-group-item mt-2", _ctx.path == 'missions' ? 'active' : ''])
          }, {
            default: _withCtx(() => [
              (_ctx.path == 'missions')
                ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                : (_openBlock(), _createElementBlock("img", _hoisted_11)),
              _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "list-group-item-title" }, "Missioni", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          _createVNode(_component_router_link, {
            to: { name: 'class' },
            class: _normalizeClass(["list-group-item mt-2", _ctx.path == 'class' ? 'active' : ''])
          }, {
            default: _withCtx(() => [
              (_ctx.path == 'class')
                ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                : (_openBlock(), _createElementBlock("img", _hoisted_13)),
              _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "list-group-item-title" }, "Classe", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ])
      ])
    ])
  ]))
}