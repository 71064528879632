import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/modals/success.svg'
import _imports_1 from '@/assets/images/modals/error.svg'
import _imports_2 from '@/assets/images/courses/FloppyInfo.svg'


const _hoisted_1 = {
  class: "modal fade",
  id: "basic-info-modal",
  tabindex: "-1",
  "aria-labelledby": "basic-info-modal-label",
  "aria-hidden": "true",
  ref: "modalRef"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "container-fluid modal-container h-100" }
const _hoisted_5 = { class: "row flex-column justify-content-center h-100" }
const _hoisted_6 = { class: "col-12 d-flex flex-column align-items-center justify-content-center" }
const _hoisted_7 = {
  key: 0,
  src: _imports_0,
  alt: "success-icon"
}
const _hoisted_8 = {
  key: 1,
  src: _imports_1,
  alt: "success-error",
  style: { fill: 'red' }
}
const _hoisted_9 = {
  key: 2,
  src: _imports_2,
  alt: "Floppy"
}
const _hoisted_10 = { class: "description" }
const _hoisted_11 = { class: "text-center m-0 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Modal "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                ($setup.props?.icon === 'success')
                  ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                  : ($setup.props?.icon === 'error')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                    : (_openBlock(), _createElementBlock("img", _hoisted_9)),
                _createElementVNode("p", _hoisted_10, _toDisplayString($props.text1), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_11, _toDisplayString($props.text2), 1 /* TEXT */),
                _createElementVNode("button", {
                  type: "button",
                  "data-bs-dismiss": "modal",
                  "aria-label": "Close",
                  onClick: $setup.closeModal,
                  class: "btn-confirm btn w-100 mt-4"
                }, _toDisplayString($props.button_text.toUpperCase()), 1 /* TEXT */),
                _createCommentVNode(" <button ref=\"submitButton\" type=\"submit\" class=\"form-submit-button btn w-100 mt-4\"\n                :disabled=\"isButtonDisabled\">\n                {{ button_text }}\n              </button> ")
              ])
            ])
          ])
        ])
      ])
    ], 512 /* NEED_PATCH */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}