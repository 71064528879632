import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, vShow as _vShow, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/logos/logo.svg'
import _imports_1 from '@/assets/images/logos/logo_mobile.svg'
import _imports_2 from '@/assets/images/icons/Mail.svg'
import _imports_3 from '@/assets/images/icons/Kye.svg'


const _hoisted_1 = {
  key: 0,
  id: "login",
  class: "container-xxl position-relative px-lg-5"
}
const _hoisted_2 = { class: "row d-flex justify-content-center mt-5 mt-lg-3" }
const _hoisted_3 = { class: "col-12 col-lg-8" }
const _hoisted_4 = {
  id: "login_inputs",
  class: "row d-flex justify-content-center mt-5"
}
const _hoisted_5 = { class: "col-12 col-lg-8 px-4 px-lg-0" }
const _hoisted_6 = {
  key: 0,
  class: "login-error-wrapper mb-2 p-1 d-flex align-items-center"
}
const _hoisted_7 = { class: "login-error-text p-0 m-0" }
const _hoisted_8 = { class: "mb-3 position-relative" }
const _hoisted_9 = { class: "mb-3 position-relative" }
const _hoisted_10 = ["type"]
const _hoisted_11 = { class: "mb-3 form-check d-flex justify-content-between" }
const _hoisted_12 = {
  key: 0,
  class: "mt-4 form-check"
}
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { class: "text-center m-0 px-4 login-header-p mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingView = _resolveComponent("LoadingView")!
  const _component_LoadingViewNoRedirect = _resolveComponent("LoadingViewNoRedirect")!
  const _component_CreatePasswordModal1 = _resolveComponent("CreatePasswordModal1")!
  const _component_CreatePasswordModal2 = _resolveComponent("CreatePasswordModal2")!
  const _component_CreatePasswordModal3 = _resolveComponent("CreatePasswordModal3")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.loadingView && !_ctx.loadingViewNoRedirect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"row pt-3 py-lg-5 mt-lg-0\" data-v-46b35b01><div class=\"col-12 d-none d-lg-block\" data-v-46b35b01><img src=\"" + _imports_0 + "\" alt=\"\" data-v-46b35b01></div><div class=\"col-12 d-flex justify-content-center d-lg-none\" data-v-46b35b01><img src=\"" + _imports_1 + "\" alt=\"\" data-v-46b35b01></div></div>", 1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[17] || (_cache[17] = _createElementVNode("h1", { class: "text-center d-none d-lg-block text-primary fs-1 fw-bold" }, " Entra in Easycode ", -1 /* HOISTED */)),
              _createCommentVNode(" <p v-if=\"isDemo\" class=\"subtitle d-none d-lg-block text-center m-0 px-4\">\n          Accedi con la tua mail e la password demo-easycode.\n        </p>\n        <p v-else class=\"subtitle d-none d-lg-block text-center m-0 px-4\">\n          Accedi con la mail e la password fornite dalla tua scuola.\n        </p> "),
              _cache[18] || (_cache[18] = _createElementVNode("h1", { class: "text-center d-block d-lg-none text-primary login-header-title" }, " Entra in Easycode ", -1 /* HOISTED */)),
              _createCommentVNode(" <p v-if=\"isDemo\" class=\"d-block d-lg-none text-center m-0 px-4 login-header-p\">\n          Accedi con la tua mail e la password demo-easycode.\n        </p>\n        <p v-else class=\"d-block d-lg-none text-center m-0 px-4 login-header-p\">\n          Accedi con la mail e la password fornite dalla tua scuola.\n        </p> "),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.error && _ctx.error?.message)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.error?.message), 1 /* TEXT */)
                      ]))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("form", {
                    onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[7] || (_cache[7] = _createElementVNode("label", {
                        for: "InputEmail1",
                        class: "form-label ms-3"
                      }, "Email", -1 /* HOISTED */)),
                      _withDirectives(_createElementVNode("input", {
                        type: "email",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                        required: "",
                        class: _normalizeClass(["form-control px-5", [
                  _ctx.email != '' ? 'active' : '',
                  _ctx.error && _ctx.error?.message ? 'is-invalid' : '',
                ]]),
                        id: "InputEmail1",
                        placeholder: "Inserisci la mail",
                        "aria-describedby": "emailHelp"
                      }, null, 2 /* CLASS */), [
                        [_vModelText, _ctx.email]
                      ]),
                      _cache[8] || (_cache[8] = _createElementVNode("img", {
                        class: "position-absolute",
                        style: {"top":"45px","left":"20px"},
                        src: _imports_2,
                        alt: ""
                      }, null, -1 /* HOISTED */))
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _cache[9] || (_cache[9] = _createElementVNode("label", {
                        for: "InputPassword1",
                        class: "form-label ms-3"
                      }, "Password", -1 /* HOISTED */)),
                      _withDirectives(_createElementVNode("input", {
                        type: _ctx.showPassword ? 'text' : 'password',
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                        required: "",
                        class: _normalizeClass(["form-control px-5", [
                    _ctx.password != '' ? 'active' : '',
                    _ctx.error && _ctx.error?.message ? 'is-invalid' : '',
                  ]]),
                        placeholder: "Inserisci la password",
                        id: "InputPassword1"
                      }, null, 10 /* CLASS, PROPS */, _hoisted_10), [
                        [_vModelDynamic, _ctx.password]
                      ]),
                      _cache[10] || (_cache[10] = _createElementVNode("img", {
                        class: "position-absolute",
                        style: {"top":"45px","left":"20px"},
                        src: _imports_3,
                        alt: ""
                      }, null, -1 /* HOISTED */))
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          class: "form-check-input",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showPassword) = $event)),
                          id: "Check1"
                        }, null, 512 /* NEED_PATCH */), [
                          [_vModelCheckbox, _ctx.showPassword]
                        ]),
                        _cache[11] || (_cache[11] = _createElementVNode("label", {
                          class: "form-check-label",
                          for: "Check1"
                        }, "Mostra password", -1 /* HOISTED */))
                      ]),
                      _createElementVNode("a", {
                        class: "switch-button pb-1",
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToForgotPassword && _ctx.goToForgotPassword(...args)))
                      }, _cache[12] || (_cache[12] = [
                        _createElementVNode("span", null, "Password dimenticata?", -1 /* HOISTED */)
                      ]))
                    ]),
                    _withDirectives(_createElementVNode("div", null, [
                      _cache[14] || (_cache[14] = _createElementVNode("p", { class: "privacy-text mt-5" }, [
                        _createTextVNode("Ai sensi e per gli effetti degli artt. 6, 7, 12, 13 del Reg. UE 2016/679 – GDPR, cliccando su “Accedi”, dichiaro di aver preso visione dell' "),
                        _createElementVNode("a", {
                          href: "https://easycode.org/assets/docs/easycode_Luna_Labs_Privacy_Policy_Website.pdf",
                          target: "_blank"
                        }, "informativa per il trattamento dei dati personali"),
                        _createTextVNode(". ")
                      ], -1 /* HOISTED */)),
                      (_ctx.isDemo)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _withDirectives(_createElementVNode("input", {
                              required: "",
                              type: "checkbox",
                              class: "form-check-input",
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newsletter) = $event)),
                              id: "Check2"
                            }, null, 512 /* NEED_PATCH */), [
                              [_vModelCheckbox, _ctx.newsletter]
                            ]),
                            _cache[13] || (_cache[13] = _createElementVNode("label", { class: "accept-newsletter" }, "Presto il mio consenso per l’invio di comunicazioni promozionali (compresa la newsletter) da parte del sito a mezzo email e riferita a prodotti propri", -1 /* HOISTED */))
                          ]))
                        : _createCommentVNode("v-if", true)
                    ], 512 /* NEED_PATCH */), [
                      [_vShow, _ctx.isDemo]
                    ]),
                    _createElementVNode("button", {
                      ref: "submitButton",
                      type: "submit",
                      class: "form-submit-button btn w-100 mt-4",
                      disabled: _ctx.email == '' || _ctx.password == ''
                    }, " ACCEDI ", 8 /* PROPS */, _hoisted_13)
                  ], 32 /* NEED_HYDRATION */)
                ])
              ]),
              _createElementVNode("p", _hoisted_14, [
                _cache[16] || (_cache[16] = _createTextVNode(" Non hai un account? ")),
                _createElementVNode("a", {
                  class: "switch-button pb-1",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.goToSignUp && _ctx.goToSignUp(...args)))
                }, _cache[15] || (_cache[15] = [
                  _createElementVNode("span", null, "REGISTRATI", -1 /* HOISTED */)
                ])),
                _createCommentVNode(" <button class=\"btn btn-confirm switch-button\" @click=\"goToSignUp\">Registrati</button> ")
              ])
            ])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.loadingView)
      ? (_openBlock(), _createBlock(_component_LoadingView, { key: 1 }))
      : _createCommentVNode("v-if", true),
    (_ctx.loadingViewNoRedirect)
      ? (_openBlock(), _createBlock(_component_LoadingViewNoRedirect, { key: 2 }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_CreatePasswordModal1),
    _createVNode(_component_CreatePasswordModal2),
    _createVNode(_component_CreatePasswordModal3)
  ], 64 /* STABLE_FRAGMENT */))
}