import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/frame/reward1.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Toggle "),
    _cache[0] || (_cache[0] = _createElementVNode("button", {
      id: "ConfirmAvatarButton",
      type: "button",
      class: "d-none",
      "data-bs-toggle": "modal",
      "data-bs-target": "#ConfirmAvatar"
    }, null, -1 /* HOISTED */)),
    _createCommentVNode(" Modal "),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"modal fade\" id=\"ConfirmAvatar\" tabindex=\"-1\" aria-labelledby=\"ConfirmAvatarLabel\" aria-hidden=\"true\" data-v-a8a09eec><div class=\"modal-dialog modal-dialog-slideout modal-lg modal-dialog-centered\" data-v-a8a09eec><div class=\"modal-content m-4 m-lg-0\" data-v-a8a09eec><div class=\"row flex-column-reverse flex-lg-row p-4 p-lg-5\" data-v-a8a09eec><div class=\"col-12 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-between mt-3 mt-lg-0\" data-v-a8a09eec><h2 class=\"text-primary modal-title\" data-v-a8a09eec>Vai così!</h2><p class=\"fs-4 modal-p mt-3\" data-v-a8a09eec> Stai andando alla grande, ecco per te un altro distintivo. Ti incuriosisce vedere quanti ne puoi conquistare? <br data-v-a8a09eec><br data-v-a8a09eec> Vai avanti e scoprilo! </p><button type=\"button\" class=\"btn btn-platform mt-3\" data-bs-toggle=\"modal\" data-bs-target=\"#StartEasycodeModal\" data-v-a8a09eec> VAI AVANTI </button></div><div class=\"col-12 col-lg-6 d-flex justify-content-center\" data-v-a8a09eec><img class=\"img-fluid\" src=\"" + _imports_0 + "\" alt=\"floppy\" data-v-a8a09eec></div></div></div></div></div>", 1))
  ], 64 /* STABLE_FRAGMENT */))
}