import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12 p-0 mt-3" }
const _hoisted_2 = { class: "container-fluid dashboard-card-wrapper" }
const _hoisted_3 = { class: "row flex-nowrap flex-lg-wrap mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CourseCardHigh = _resolveComponent("CourseCardHigh")!
  const _component_CourseCard = _resolveComponent("CourseCard")!
  const _component_DashboardCmpWrapper = _resolveComponent("DashboardCmpWrapper")!

  return (_openBlock(), _createBlock(_component_DashboardCmpWrapper, {
    loading: _ctx.loading,
    title: "I tuoi corsi",
    type: "courses"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.role == 'high-student' && _ctx.width > 992)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.courses, (course) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: course.id,
                    class: "col-12"
                  }, [
                    _createVNode(_component_CourseCardHigh, { course: course }, null, 8 /* PROPS */, ["course"])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.courses, (course) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: course.id,
                    class: "col-9 col-sm-5 col-md-5 col-lg-3 d-flex"
                  }, [
                    _createVNode(_component_CourseCard, { course: course }, null, 8 /* PROPS */, ["course"])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading"]))
}