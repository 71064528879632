import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/frame/key.png'


const _hoisted_1 = {
  class: "modal fade",
  id: "WelcomeModal1",
  tabindex: "-1",
  "aria-labelledby": "WelcomeModal1Label",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-slideout modal-lg modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content m-4 m-lg-0" }
const _hoisted_4 = { class: "row flex-column-reverse flex-lg-row p-4 p-lg-5" }
const _hoisted_5 = { class: "col-12 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-between mt-3 mt-lg-0" }
const _hoisted_6 = { class: "text-primary modal-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Modal "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString("Ciao " + _ctx.name + "!"), 1 /* TEXT */),
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "fs-4 modal-p mt-3" }, [
                _createTextVNode(" Entrando nel mondo di Easycode hai conquistato il tuo primo distintivo. "),
                _createElementVNode("br"),
                _createElementVNode("br"),
                _createTextVNode(" Continua e conquistali tutti! ")
              ], -1 /* HOISTED */)),
              _createElementVNode("button", {
                class: "btn btn-platform mt-3",
                type: "button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.next()))
              }, " VAI AVANTI ")
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-12 col-lg-6" }, [
              _createElementVNode("img", {
                class: "img-fluid",
                src: _imports_0,
                alt: "floppy"
              })
            ], -1 /* HOISTED */))
          ])
        ])
      ]),
      _createCommentVNode(" Toggle "),
      _cache[3] || (_cache[3] = _createElementVNode("button", {
        id: "WelcomeModal1Button",
        type: "button",
        class: "d-none",
        "data-bs-toggle": "modal",
        "data-bs-target": "#WelcomeModal1"
      }, null, -1 /* HOISTED */)),
      _createCommentVNode(" Close "),
      _cache[4] || (_cache[4] = _createElementVNode("button", {
        id: "WelcomeModal1Close",
        class: "d-none",
        "data-bs-dismiss": "modal",
        "aria-label": "Close"
      }, null, -1 /* HOISTED */))
    ])
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}