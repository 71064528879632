<template>
  <div class="d-flex w-100" :class = "center != true ? '' : 'align-items-center'">
    <span v-if="explicit" class="progress-num me-1">{{
      percentage + "%"
    }}</span>
    <div class="progress w-100">
      <div
        class="progress-bar"
        :style="{ width: percentage + '%' }"
        role="progressbar"
        aria-label="Progress bar"
        :aria-valuenow="percentage"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div v-if="achieved !== undefined && total !== undefined" class="achieved-num"
    :class = "center != true ? 'mt-2' : 'ms-2'">{{
      achieved + "/" + total
    }}</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProgressBarPurple",
  props: {
    explicit: Boolean,
    center: Boolean,
    percentage: Number,
    achieved: Number,
    total: Number,
  },
});
</script>

<style lang="scss" scoped>
.progress {
  height: 10px;
}

.progress-bar {
  background-color: #8d4bf6;
}
.progress-num {
  font-size: 1rem;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: $platform-text-color-1;
}

.achieved-num{
  font-size: 1rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
}
</style>
