import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12 py-1 py-lg-0 px-1 px-md-0" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 d-flex justify-content-between" }
const _hoisted_4 = {
  class: "dropdown-toggle btn d-flex align-items-center",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_5 = { class: "col-12 col-lg-1" }
const _hoisted_6 = { class: "avatar-circle mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PublicNavbarPrimary = _resolveComponent("PublicNavbarPrimary")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HeaderDropdown = _resolveComponent("HeaderDropdown")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["navbar position-sticky top-0 px-5", [
      !_ctx.elementari || _ctx.currentRoute == 'landing' ? 'border-nav' : '',
    ]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.elementari)
            ? (_openBlock(), _createBlock(_component_PublicNavbarPrimary, { key: 0 }))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            class: _normalizeClass(["dropdown d-flex align-items-center", !_ctx.elementari ? 'ms-auto' : ''])
          }, [
            _createCommentVNode(" <div v-if=\"!elementari\" class=\"position-relative\">\n              <router-link :to=\"{ name: 'notifications' }\" class=\"me-3\"\n                ><img src=\"@/assets/images/icons/x32/Notifiche-fill.svg\" alt=\"\"\n              /></router-link>\n              <div\n                v-if=\"layoutStore.getNotifications.length > 0\"\n                class=\"notifications-counter\"\n              ></div>\n            </div> "),
            _createVNode(_component_router_link, {
              to: {name: 'role-select'},
              class: "course-header-high-link pb-1"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Cambia grado scolastico")
              ])),
              _: 1 /* STABLE */
            }),
            _createElementVNode("button", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.avatarLetters), 1 /* TEXT */)
              ]),
              _createCommentVNode(" <div v-if=\"!elementari\" class=\"d-flex align-items-center ms-1\">\n                <UserAvatar\n                  class=\"svg-container-sm\"\n                  :avatar=\"userStore.currentUser?.avatar?.base\"\n                  :part1=\"userStore.currentUser?.avatar?.parts.part1\"\n                  :part2=\"userStore.currentUser?.avatar?.parts.part2\"\n                  :part3=\"userStore.currentUser?.avatar?.parts.part3\"\n                  :part4=\"userStore.currentUser?.avatar?.parts.part4\"\n                  :part5=\"userStore.currentUser?.avatar?.parts.part5\"\n                />\n                <h2 class=\"student-name ms-2\">\n                  {{ userStore.currentUser?.nickname ?? (userStore.currentUser?.name ?? \"\") }}\n                </h2>\n              </div>\n              <div v-else class=\"ms-1\">\n                <UserAvatar\n                  class=\"svg-container-lg\"\n                  :avatar=\"userStore.currentUser?.avatar?.base\"\n                  :part1=\"userStore.currentUser?.avatar?.parts.part1\"\n                  :part2=\"userStore.currentUser?.avatar?.parts.part2\"\n                  :part3=\"userStore.currentUser?.avatar?.parts.part3\"\n                  :part4=\"userStore.currentUser?.avatar?.parts.part4\"\n                  :part5=\"userStore.currentUser?.avatar?.parts.part5\"\n                />\n              </div> ")
            ]),
            _createVNode(_component_HeaderDropdown)
          ], 2 /* CLASS */)
        ])
      ])
    ])
  ], 2 /* CLASS */))
}