<template>
  <input
    id="date-input"
    v-model="formattedDate"
    type="text"
    placeholder="gg/mm/aaaa"
    @input="applyMask"
    maxlength="10"
    class="form-control px-5"
    :class="[
        formattedDate !== '' ? 'active' : '',
      ]"
    required
  />
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:modelValue', 'isAdultChanged']);

const formattedDate = ref(props.modelValue);

// Funzione che applica la maschera
const applyMask = (event) => {
  let value = event.target.value.replace(/\D/g, ''); // Rimuove tutto tranne i numeri

  // Verifica i giorni
  if (value.length >= 2) {
    let day = value.slice(0, 2);
    if (parseInt(day) < 1 || parseInt(day) > 31) {
      day = '';
    }
    value = day + (value.length > 2 ? '/' + value.slice(2) : '');
  }

  // Verifica i mesi
  if (value.length >= 5) {
    let month = value.slice(3, 5);
    if (parseInt(month) < 1 || parseInt(month) > 12) {
      month = '';
    }
    value = value.slice(0, 3) + month + (value.length > 5 ? '/' + value.slice(5) : '');
  }

  // Verifica gli anni
  if (value.length === 10) {
    let year = value.slice(6, 10);
    if (parseInt(year) < 1900 || parseInt(year) > new Date().getFullYear()) {
      year = '';
    }
    value = value.slice(0, 6) + year;
  }

  formattedDate.value = value;
  emit('update:modelValue', value);
};

// Funzione per calcolare l'età e verificare se la persona è maggiorenne
const isAdult = computed(() => {
  if (formattedDate.value.length === 10) {
    const [day, month, year] = formattedDate.value.split('/');
    const birthDate = new Date(`${year}-${month}-${day}`);
    const today = new Date();

    // Calcola l'età
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Se il mese corrente è precedente al mese di nascita o è lo stesso mese ma il giorno è precedente, diminuisci l'età di 1
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age >= 18;
  }
  return null; // Ritorna null se la data inserita non è completa
});

// Watch per emettere isAdult al componente genitore
watch(isAdult, (newValue) => {
  emit('isAdultChanged', newValue);
});
</script>
