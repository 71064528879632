import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyStart = _resolveComponent("EmptyStart")!
  const _component_CourseStart = _resolveComponent("CourseStart")!
  const _component_DashboardCmpWrapper = _resolveComponent("DashboardCmpWrapper")!

  return (_openBlock(), _createBlock(_component_DashboardCmpWrapper, {
    loading: false,
    title: _ctx.title,
    type: "courses",
    viewAll: false,
    class: _normalizeClass({ 'start-bg': _ctx.courses?.length && _ctx.courses?.length > 0 })
  }, {
    default: _withCtx(() => [
      (_ctx.courses?.length == 0 || _ctx.loading)
        ? (_openBlock(), _createBlock(_component_EmptyStart, { key: 0 }))
        : (_openBlock(), _createBlock(_component_CourseStart, {
            key: 1,
            courses: _ctx.courses
          }, null, 8 /* PROPS */, ["courses"]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "class"]))
}