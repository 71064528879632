import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import LoginView from "../views/auth/LoginView.vue";
import SignUpView from "@/views/auth/SignUpView.vue";
import ForgotPasswordView from "@/views/auth/ForgotPasswordView.vue";
import LandingView from '@/views/publicFlow/LandingView.vue'
import RoleSelectView from "@/views/publicFlow/RoleSelectView.vue"
import Layout from '@/layout/Layout.vue'
import StartLayout from "@/layout/StartLayout.vue";
import RoleLayout from "@/layout/RoleLayout.vue";

import Start from "../views/start/Start.vue";
import Avatar from "../views/start/Avatar.vue";


const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    redirect: "/dashboard",
    component: Layout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/landing",
        name: "landing",
        component: LandingView,
      },
      {
        path: "/courses",
        name: "courses",
        component: () => import("@/views/Courses.vue"),
      },
      {
        path: "/course/:uuid",
        name: "course-page",
        component: () => import("@/views/CoursePage.vue"),
      },
      {
        path: "/lesson/:uuid",
        name: "lesson-page",
        component: () => import("@/views/LessonPage.vue"),
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/views/Calendar.vue"),
      },
      {
        path: "/missions",
        name: "missions",
        component: () => import("@/views/Missions.vue"),
      },
      {
        path: "/mission/:uuid",
        name: "mission-page",
        component: () => import("@/views/MissionPage.vue"),
      },
      {
        path: "/class",
        name: "class",
        component: () => import("@/views/Class.vue"),
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/Notifications.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/Profile.vue"),
      },
      {
        path: "/profile/edit",
        name: "profile-edit",
        component: () => import("@/views/profile/EditProfile.vue"),
      },
      {
        path: "/profile/avatar",
        name: "change-avatar",
        component: () => import("@/views/profile/ChangeAvatar.vue"),
      },
    ],
  },
  {
    path: "/video/:id",
    name: "video",
    component: () => import("@/views/Video.vue"),
  },
  {
    path: "/",
    redirect: "/start",
    component: StartLayout,
    children: [
      {
        path: "/start",
        name: "start",
        component: Start,
      },
      {
        path: "/choose-avatar",
        name: "chooseAvatar",
        component: Avatar,
      },
      {
        path: "/login",
        name: "login",
        component: LoginView,
      },
      {
        path: "/signup",
        name: "signup",
        component: SignUpView,
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPasswordView,
      },
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/Error404.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
      },
    ],
  },

    // rotte pubbliche - selezione ruolo - RoleLayout
    {
      path: "/",
      component: RoleLayout,
      children: [
        {
          path: "/role-select",
          name: "role-select",
          component: RoleSelectView,
        },
      ]
    },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
/* eslint-disable */
router.beforeEach((to, from, next) => {

  const token = localStorage.getItem("token");

  if (to.name === "signup") {
    next()
    return
  }

  if (to.name === "forgot-password") {
    next()
    return
  }

  if (to.name === "login") {
    if (token && token != "") {
      next({
        path: "/dashboard",
      });
    } else {
      next();
    }
  } else {
    if (token && token != "") {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
