import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logos/logo.svg'
import _imports_1 from '@/assets/images/logos/logo45x45.svg'
import _imports_2 from '@/assets/images/avatars/Avatar.png'
import _imports_3 from '@/assets/images/icons/Studente.svg'


const _hoisted_1 = {
  id: "home",
  class: "container-fluid px-lg-5 py-5 pt-lg-4"
}
const _hoisted_2 = { class: "row d-flex flex-column align-items-center justify-content-center mt-lg-5 pt-lg-5" }
const _hoisted_3 = { class: "col-12 col-lg-6 px-4 px-lg-0" }
const _hoisted_4 = { class: "mb-3 position-relative" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreatePasswordModal1 = _resolveComponent("CreatePasswordModal1")!
  const _component_CreatePasswordModal2 = _resolveComponent("CreatePasswordModal2")!
  const _component_CreatePasswordModal3 = _resolveComponent("CreatePasswordModal3")!
  const _component_WelcomeModal1 = _resolveComponent("WelcomeModal1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"row\" data-v-169739ab><div class=\"col-12 d-none d-lg-flex justify-content-end\" data-v-169739ab><img src=\"" + _imports_0 + "\" alt=\"\" data-v-169739ab></div><div class=\"col-12 d-flex justify-content-center d-lg-none\" data-v-169739ab><img src=\"" + _imports_1 + "\" alt=\"\" data-v-169739ab></div></div>", 1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"col-10 col-lg-8\" data-v-169739ab><h1 class=\"title text-center text-primary my-4\" data-v-169739ab> Chi è il giocatore di Easycode? </h1><p class=\"subtitle text-center m-0 px-4\" data-v-169739ab> Per iniziare, scegli un nickname. </p></div><div class=\"col-12 col-md-6 d-flex justify-content-center mt-3\" data-v-169739ab><img width=\"250px\" src=\"" + _imports_2 + "\" alt=\"\" data-v-169739ab></div>", 2)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", null, [
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("label", {
              for: "studentName",
              class: "form-label ms-3"
            }, "Nickname", -1 /* HOISTED */)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
              class: "form-control px-5",
              id: "studentName",
              required: "",
              placeholder: "Inserisci il nome",
              "aria-describedby": "studentNameHelp"
            }, null, 512 /* NEED_PATCH */), [
              [_vModelText, _ctx.name]
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("img", {
              class: "position-absolute",
              style: {"top":"45px","left":"20px"},
              src: _imports_3,
              alt: ""
            }, null, -1 /* HOISTED */))
          ]),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.startWelcome())),
            class: "form-submit-button btn btn-platform w-100 mt-4",
            disabled: _ctx.name == ''
          }, " CONFERMA ", 8 /* PROPS */, _hoisted_5)
        ])
      ])
    ]),
    _createVNode(_component_CreatePasswordModal1),
    _createVNode(_component_CreatePasswordModal2),
    _createVNode(_component_CreatePasswordModal3),
    _createVNode(_component_WelcomeModal1, { name: _ctx.name }, null, 8 /* PROPS */, ["name"])
  ]))
}