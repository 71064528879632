<template>
  <div id="start-layout" class="container-fluid bg-gradient p-0">
    <router-view v-if="!loading" :start="start" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref,  onMounted } from "vue";

export default defineComponent({
  name: "StartLayout",
  setup() {
    const loading = ref(false);
    const start = ref(false);

    // const loadData = async () => {
    //     loading.value = true;
    //   const store = await useUserInfoStore();
    //   await store
    //     .verifyAuth()
    //     .then(() => {
    //       const userInfo = store.currentUserInfo;
    //       const user = store.currentUser;
// 
    //       if (
    //         !userInfo.must_change_password &&
    //         user.nickname != null &&
    //         user.avatar != null
    //       ) {
    //         router.push("/");
    //       } else {
    //         start.value = true;
    //         loading.value = false;
    //       }
// 
    //     })
    //     .catch(() => {
    //       loading.value = false;
    //     });
    // };

    // watch(route, async () => {
    //   loading.value = true;
    //   await loadData();
    // });
    // loadData();

    onMounted( () => {
        console.log("ON MOUNTED START LAYOUT");
    });

    return {
      loading,
      start,
    };
  },
});
</script>

<style lang="scss" scoped>
#start-layout {
  min-height: 100vh !important;
}
</style>
