import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dashboard-start dashboard-rounded col-12 px-3 px-md-4 py-5 mt-5" }
const _hoisted_2 = { class: "row justify-content-center px-3" }
const _hoisted_3 = { class: "col-12 d-flex justify-content-between align-items-center p-0" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "fs-2 fw-semibold text-primary ms-3 mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.getIcon(),
            alt: "Dashboard component image"
          }, null, 8 /* PROPS */, _hoisted_5),
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.title), 1 /* TEXT */)
        ]),
        (_ctx.viewAll)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: '/' + _ctx.type,
              class: _normalizeClass(["d-none d-md-flex", [_ctx.userInfoStore.getRole == 'primary-student' ? 'dashboard-start-link-primary' : 'dashboard-start-link']])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.userInfoStore.getRole == 'primary-student' ? 'Guarda Tutti' : 'Vedi Tutti'), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to", "class"]))
          : _createCommentVNode("v-if", true)
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
        : _renderSlot(_ctx.$slots, "default", { key: 1 }),
      _createCommentVNode(" div\n        class=\"col-12 d-flex justify-content-center mt-5 d-md-none\"\n        v-if=\"viewAll\"\n      >\n        <router-link :to=\"'/' + type\" class=\"dashboard-start-link\"\n          >GUARDA TUTTI</router-link\n        >\n      </div ")
    ])
  ]))
}