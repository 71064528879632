import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/icons/Crown-Lesson-Yellow.svg'
import _imports_1 from '@/assets/images/icons/x56/User,Profile.svg'
import _imports_2 from '@/assets/images/icons/x56/Logout.svg'


const _hoisted_1 = { class: "dropdown-menu" }
const _hoisted_2 = { class: "d-flex flex-column align-items-center my-3" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "d-flex flex-column align-items-center justify-content-center",
  style: {"width":"80%"}
}
const _hoisted_5 = {
  key: 0,
  class: "personal-width"
}
const _hoisted_6 = {
  key: 1,
  class: "d-flex align-items-end mt-3 mb-1 w-100"
}
const _hoisted_7 = { class: "progress w-100" }
const _hoisted_8 = ["aria-valuenow"]
const _hoisted_9 = {
  key: 2,
  class: "user-level ms-2"
}
const _hoisted_10 = { class: "d-flex flex-column mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBarPurple = _resolveComponent("ProgressBarPurple")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.userStore.getRole == 'high-student' ? 'd-flex flex-row align-items-center':'d-flex flex-column align-items-center personal-width')
      }, [
        _createElementVNode("img", {
          src: _ctx.userStore.currentLevel?.imageUnlock,
          class: _normalizeClass(["img-fluid img-current-level", _ctx.userStore.getRole == 'high-student' ? 'img-current-level-high' : 'img-current-level']),
          alt: ""
        }, null, 10 /* CLASS, PROPS */, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", {
            class: _normalizeClass(["dropdown-items-title mt-3", _ctx.userStore.getRole == 'high-student'? '':'text-center'])
          }, _toDisplayString(_ctx.userStore.currentLevel?.level ?? ""), 3 /* TEXT, CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(["w-100 d-flex align-items-end", _ctx.userStore.getRole == 'high-student'? '':'text-center'])
          }, [
            (_ctx.userStore.getRole == 'high-student')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_ProgressBarPurple, {
                    class: "d-flex flex-column mt-2",
                    achieved: _ctx.userStore.currentUser.level,
                    total: _ctx.userStore.currentLevel?.points,
                    percentage: _ctx.completionPercentage,
                    explicit: false,
                    center: false
                  }, null, 8 /* PROPS */, ["achieved", "total", "percentage"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    src: _imports_0,
                    class: "crown img-fluid",
                    style: {"height":"20px"},
                    alt: ""
                  }, null, -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", {
                      class: "progress-bar",
                      style: _normalizeStyle({ width: _ctx.completionPercentage + '%' }),
                      role: "progressbar",
                      "aria-label": "Level completion percentage",
                      "aria-valuenow": _ctx.completionPercentage,
                      "aria-valuemin": "0",
                      "aria-valuemax": "100"
                    }, null, 12 /* STYLE, PROPS */, _hoisted_8)
                  ])
                ])),
            (_ctx.userStore.getRole != 'high-student')
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.user.level /* + "/" + userStore.currentLevel.points */), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */)
        ])
      ], 2 /* CLASS */),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_router_link, {
          to: { name: 'profile' },
          class: "btn dropdown-items-btn ps-0"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("img", {
              src: _imports_1,
              class: "img-fluid me-2",
              style: {"width":"42px"},
              alt: ""
            }, null, -1 /* HOISTED */),
            _createTextVNode(" VAI AL PROFILO ")
          ])),
          _: 1 /* STABLE */
        }),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout())),
          class: "btn dropdown-items-btn dropdown-logout ps-0 mt-3"
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("img", {
            src: _imports_2,
            class: "img-fluid",
            style: {"width":"42px"},
            alt: ""
          }, null, -1 /* HOISTED */),
          _createTextVNode(" ESCI ")
        ]))
      ])
    ])
  ]))
}