import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/frame/reward3.svg'


const _hoisted_1 = {
  class: "modal fade",
  id: "StartEasycodeModal",
  tabindex: "-1",
  "aria-labelledby": "StartEasycodeModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-slideout modal-lg modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content m-4 m-lg-0" }
const _hoisted_4 = { class: "row flex-column-reverse flex-lg-row p-4 p-lg-5" }
const _hoisted_5 = { class: "col-12 col-lg-6 text-center text-lg-start d-flex flex-column justify-content-between mt-3 mt-lg-0" }
const _hoisted_6 = { class: "text-primary modal-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("button", {
              type: "button",
              id: "StartEasycodeModalClose",
              class: "btn-close d-none",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, null, -1 /* HOISTED */)),
            _createElementVNode("h2", _hoisted_6, _toDisplayString("Ottimo lavoro " + _ctx.name + "!"), 1 /* TEXT */),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "fs-4 modal-p mt-3" }, " Il tuo personaggio è perfetto! Ora puoi iniziare la tua avventura nel magico mondo di Easycode. ", -1 /* HOISTED */)),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.next())),
              type: "button",
              class: "btn btn-platform mt-3"
            }, " INIZIA L'AVVENTURA ")
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-12 col-lg-6 d-flex justify-content-center ps-0 ps-lg-5" }, [
            _createElementVNode("img", {
              class: "img-fluid",
              src: _imports_0,
              alt: "floppy"
            })
          ], -1 /* HOISTED */))
        ])
      ])
    ])
  ]))
}