<template>
  <div id="dashboard" class="col-12 p-4 mb-5 mt-lg-5">
    <div class="row m-0 flex-column">
      <div class="col-12 col-md-10 dashboard-welcome my-4 gx-5 gx-lg-0 mx-lg-0">
        <template v-if="currentUserInfo.role == 'secondary-student'">
          <h1 class="dashboard-welcome-title fs-1 fw-bold text-primary">
            {{ "Ciao " +(user?.name ?? "") + ", impariamo qualcosa?" }}
          </h1>
          <p class="dashboard-welcome-p fs-4 fw-medium text-dark">
            Riprendi i corsi da dove li hai lasciati.
          </p>
        </template>
        <template v-if="currentUserInfo.role == 'high-student'">
          <h1 class="dashboard-welcome-title fs-1 fw-bold text-primary">
            {{ "Hey " + (user?.name ?? "") + ", sei qui!" }}
          </h1>
          <p class="dashboard-welcome-p fs-4 fw-medium text-dark">
            Riprendi i corsi da dove li hai lasciati!
          </p>
        </template>
        <template v-if="currentUserInfo.role == 'primary-student'">
          <h1 class="dashboard-welcome-title fs-1 fw-bold text-primary">
            {{ "Ciao " + (user?.name ?? "") + ", continua la tua avventura!" }}
          </h1>
        </template>
      </div>
      <Start :loading="loading" :courses="startedCourses"/>
      <Courses :role="currentUserInfo.role" :loading="loading" :courses="yourCourses" />
      <!-- <Missions  :class="currentUserInfo.role == 'primary-student' ? 'order-5' : ''"/> -->
      <!-- <Class  :class="currentUserInfo.role == 'primary-student' ? 'order-4' : ''"/> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";

import { useUserInfoStore } from "@/store";

import ApiService from "@/core/services/ApiService";

import Course from "@/core/data/course";

import Start from "@/components/dashbaord/Start.vue";
import Courses from "@/components/dashbaord/Courses.vue";
// import Missions from "@/components/dashbaord/Missions.vue";
// import Class from "@/components/dashbaord/Class.vue";

export default defineComponent({
  name: "Landing",
  components: {
    Start,
    Courses,
    // Missions,
    // Class,
  },
  setup() {
    console.log("LANDING VIEW");
    const store = useUserInfoStore();

    const user = store.currentUser;
    const currentUserInfo = store.currentUserInfo;
    console.log('ROLE', currentUserInfo.role);
    const loading = ref(true);
    const courses = ref([] as Course[]);

    const startedCourses = computed( () => {
        return courses.value.reduce( (carry, value) => {
            if( carry.length < 2 && value.video_count_completed > 0 && value.video_count != value.video_count_completed) {
                carry.push(value);
            }
            return carry;
        }, [] as Course[]);
    });

    const yourCourses = computed( () => {
        let c = courses.value.reduce( (carry, value) => {
            if( carry.length < 4 && value.video_count_completed == 0) {
                carry.push(value);
            }
            return carry;
        }, [] as Course[]);
        if( c.length < 4 ) {
            c = courses.value.reduce( (carry, value) => {
                if( carry.length < 4 && value.video_count_completed > 0) {
                    carry.push(value);
                }
                return carry;
            }, c);
        }
        return c;
    });

    const data = {
      data: {
        filter: [
          { field: "grades", value: currentUserInfo.role.split("-")[0] },
        ],
        flags: [
          "with-certificates-by-course-stats",
          "with-lessons-and-video-count",
        ],
        //pagination: {
        //  "page-size": 4,
        //},
      },
    };

    ApiService.post("/api/courses/search", data).then((response) => {
      const key = response.data.data.key;

      ApiService.get("/api/courses/search-results/" + key).then((response) => {
        courses.value = response.data.data.courses;
        console.log(courses.value);
        loading.value = false;
      });
    });

    return {
      user,
      currentUserInfo,
      loading,
      courses,
      startedCourses,
      yourCourses
    };
  },
});
</script>

<style lang="scss">
#dashboard {
    padding: 0 104px;
}
.fs-1 {
  letter-spacing: 0.01em;
}
.order-4 {
    order: 4;
}
.order-5 {
    order: 5;
}
</style>
