import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "progress-num me-1"
}
const _hoisted_2 = { class: "progress w-100" }
const _hoisted_3 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex w-100", _ctx.center != true ? '' : 'align-items-center'])
  }, [
    (_ctx.explicit)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.percentage + "%"), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "progress-bar",
        style: _normalizeStyle({ width: _ctx.percentage + '%' }),
        role: "progressbar",
        "aria-label": "Progress bar",
        "aria-valuenow": _ctx.percentage,
        "aria-valuemin": "0",
        "aria-valuemax": "100"
      }, null, 12 /* STYLE, PROPS */, _hoisted_3)
    ]),
    (_ctx.achieved !== undefined && _ctx.total !== undefined)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["achieved-num", _ctx.center != true ? 'mt-2' : 'ms-2'])
        }, _toDisplayString(_ctx.achieved + "/" + _ctx.total), 3 /* TEXT, CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}