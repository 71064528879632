<template>
  <div class="d-flex align-items-end mt-4 w-100">
    <img
      v-if="large"
      src="@/assets/images/icons/crown.svg"
      class="img-fluid crown-large"
      alt=""
    />
    <img
      v-else
      src="@/assets/images/icons/Crown-Lesson-Medium-Yellow.svg"
      class="img-fluid crown"
      alt=""
    />
    <div class="progress w-100 ms-1">
      <div
        class="progress-bar"
        :style="{ width: percentage + '%' }"
        role="progressbar"
        aria-label="Basic example"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div v-if="points" class="points ms-5">&nbsp;{{ points }}</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProgressBar",
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    percentage: Number,
    points: String,
  },
});
</script>

<style lang="scss" scoped>
.crown-large {
  height: 16px;
  width: 54px;
}

.crown {
  height: 20px;
}

.progress {
  height: 10px;
  margin-bottom: 2px;
}

.progress-bar {
  background-color: #e8b103;
}

.points {
  color: var(--blue-50, #03288e);
  font-weight: 600;
}
</style>
