<template>
  <div class="course-card-high row mb-4">
    <div class="col-4 h-100 ps-0">
      <img
        class="img-course-card-high"
        :src="imageURI + course?.s3_thumbnail_name"
        alt="Course image"
      />
    </div>
    <div
      class="col-8 h-100 d-flex flex-column justify-content-between pt-4 pb-3 pe-4"
    >
      <div>
        <h3 class="title-course-card-high">{{ course?.name }}</h3>
        <p class="description-course-card-high">{{ course?.description }}</p>
        <div class="row justify-content-between mt-2">
          <div class="col-7 d-flex justify-content-start">
            <span
              class="course-info-details d-flex align-items-center text-nowrap me-5"
              ><img
                class="me-1"
                src="@/assets/images/icons/x24/Time.svg"
                alt=""
              />
              {{ course?.duration  }}</span
            >
            <span
              class="course-info-details d-flex align-items-center text-nowrap me-5"
              ><img
                class="me-1"
                src="@/assets/images/icons/x24/Video-Play-Playlist.svg"
                alt=""
              />
              {{ (course?.lessons ? course?.lessons?.length : course?.lesson_count) + " Lezioni" }}</span
            >

            <span
              class="course-info-details d-flex align-items-center text-nowrap me-5"
              ><img
                class="me-1"
                src="@/assets/images/icons/x24/Badge.svg"
                alt=""
              />
              Attestato finale
            </span>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-end">
        <router-link
          :to="'/course/' + course?.id"
          class="btn btn-platform px-4 d-flex align-items-center"
          >Vai al corso</router-link
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import Course from "@/core/data/course";
export default defineComponent({
  name: "CourseCardHigh",
  props: {
    course: Object as PropType<Course>,
  },
  setup(props) {
    const imageURI = process.env.VUE_APP_S3_PATH;

//     console.log("CICCIA: ", (props.course?.lessons ? props.course?.lessons?.length : props.course?.lesson_count));
    return {
      props,
      imageURI,
    };
  },
});
</script>

<style lang="scss" scoped>
.course-card-high {
  height: 225px;
  border: 2px solid #ebf0ff;
  box-shadow: 0px 8px 16px 0px #7b879414;
  box-shadow: 0px 1px 8px 0px #7b87941f;
  border-radius: 30px;
}
.img-course-card-high {
  height: 100%;
  width: 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  object-fit: cover;
}

.title-course-card-high {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-align: left;
}

.description-course-card-high {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;

  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.course-info-details {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $platform-text-color-1;
}
</style>
