import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row m-0 d-none d-lg-block" }
const _hoisted_2 = {
  id: "content-container p-0",
  class: "container-fluid p-0"
}
const _hoisted_3 = {
  class: "row m-0 d-lg-none",
  style: {"z-index":"1"}
}
const _hoisted_4 = { class: "content-wrapper-mobile p-0" }
const _hoisted_5 = { class: "row m-0 d-none d-lg-block" }
const _hoisted_6 = {
  id: "content-container p-0",
  class: "container-fluid p-0"
}
const _hoisted_7 = {
  class: "row m-0 d-lg-none",
  style: {"z-index":"1"}
}
const _hoisted_8 = { class: "content-wrapper-mobile p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Aside = _resolveComponent("Aside")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_HeaderMobile = _resolveComponent("HeaderMobile")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_PublicAside = _resolveComponent("PublicAside")!
  const _component_PublicHeader = _resolveComponent("PublicHeader")!
  const _component_PublicHeaderMobile = _resolveComponent("PublicHeaderMobile")!
  const _component_PublicFooter = _resolveComponent("PublicFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent('script'), null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" (function(d, w, c) { w.ChatraID = '6cLAs35f45WRTnzKx'; var s = d.createElement('script'); w[c] = w[c] || function() { (w[c].q = w[c].q || []).push(arguments); }; s.async = true; s.src = 'https://call.chatra.io/chatra.js'; if (d.head) d.head.appendChild(s); })(document, window, 'Chatra'); ")
      ])),
      _: 1 /* STABLE */
    })),
    (!_ctx.loading && !_ctx.registered)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "layout",
          class: _normalizeClass(["container-xxl p-0", _ctx.role != 'primary-student' ? 'container-aside' : ''])
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.role != 'primary-student')
              ? (_openBlock(), _createBlock(_component_Aside, { key: 0 }))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(["row px-5 m-0", _ctx.role != 'primary-student' ? 'aside-margin' : ''])
              }, [
                _createVNode(_component_Header, {
                  elementari: _ctx.role == 'primary-student'
                }, null, 8 /* PROPS */, ["elementari"]),
                _createVNode(_component_router_view)
              ], 2 /* CLASS */)
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_HeaderMobile),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_view)
            ]),
            _createVNode(_component_Footer)
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (!_ctx.loading && _ctx.registered)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: "layout",
          class: _normalizeClass(["container-xxl p-0", _ctx.role != 'primary-student' ? 'container-aside' : ''])
        }, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.role != 'primary-student')
              ? (_openBlock(), _createBlock(_component_PublicAside, { key: 0 }))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass(["row px-5 m-0", _ctx.role != 'primary-student' ? 'aside-margin' : ''])
              }, [
                _createVNode(_component_PublicHeader, {
                  elementari: _ctx.role == 'primary-student',
                  avatarLetters: _ctx.avatarLetters
                }, null, 8 /* PROPS */, ["elementari", "avatarLetters"]),
                _createVNode(_component_router_view)
              ], 2 /* CLASS */)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_PublicHeaderMobile, { avatarLetters: _ctx.avatarLetters }, null, 8 /* PROPS */, ["avatarLetters"]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_router_view)
            ]),
            _createVNode(_component_PublicFooter)
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}