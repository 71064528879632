<template>
  <div class="container-fluid text-center">
    <div class="row my-3 gy-4">
      <div
        v-for="course in courses"
        :key="course.id"
        class="col-12 col-lg-6 px-4"
      >
        <div class="row card-wrapper">
          <div class="col-4 px-0 position-relative">
            <img
              class="img-fluid img-card"
              :src="imageURI + course.s3_thumbnail_name"
              alt="course image"
            />

            <router-link :to="'/course/' + course.id" class="card-play-button">
              <img
                class="img-fluid img-card-play"
                src="@/assets/images/icons/x56/Play.svg"
                alt=""
              />
            </router-link>
          </div>

          <div
            class="col-8 d-flex flex-column justify-content-between align-items-start py-3 pe-4"
          >
            <div>
              <h3 class="card-title">{{ course.name }}</h3>
              <p class="card-description mt-2 mb-0">{{ course.description }}</p>
            </div>

            <div
              v-if="currentUserInfo.getRole != 'high-student'"
              class="w-100 d-flex flex-column align-items-start"
            >
              <ProgressBar :percentage="getPercentage(course)" :large="false" />
              <span class="card-video-count m-1">{{
                course.video_count_completed + "/" + course.video_count
              }}</span>
            </div>

            <div v-else class="w-100 d-flex flex-column align-items-start">
              <ProgressBarPurple
                :percentage="getPercentage(course)"
                :explicit="false"
              />
              <span class="card-video-count m-1 mt-2">{{
                getPercentage(course) + "%"
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

import Course from "@/core/data/course";

import ProgressBar from "@/components/ProgressBar.vue";
import ProgressBarPurple from "@/components/ProgressBarPurple.vue";
import { useUserInfoStore } from "@/store";

export default defineComponent({
  name: "Course-Start",
  components: {
    ProgressBar,
    ProgressBarPurple,
  },
  props: {
    courses: Array as PropType<Course[]>,
  },
  setup() {
    const currentUserInfo = useUserInfoStore();

    const imageURI = process.env.VUE_APP_S3_PATH;

    const getPercentage = (course: Course) => {
      const percentage = Math.floor((course.video_count_completed * 100) / course.video_count)

      return percentage;
    };

    return {
      imageURI,
      currentUserInfo,
      getPercentage,
    };
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  border-radius: 24px;
  background-color: white;
  overflow: hidden;
}

.img-card {
  height: 192px;
  width: 100%;
  object-fit: cover !important;
}

.card-title {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #242424;
  text-transform: capitalize;
}

.card-description {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: #3e4c59;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.card-video-count {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: #3e4c59;
}

.card-play-button {
  width: 50px;
  height: 50px;

  border-radius: 58px;
  border: 2px;
  gap: 10px;
  opacity: 0.9px;

  background: #3ce8da;

  border: 2px solid #ffffff;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.img-card-play {
  height: 80%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 8%;
  right: 0;
  margin: auto;
}

@media screen and (min-width: 992px) {
  .card-play-button {
    width: 80px;
    height: 80px;
  }
}
</style>
