<template>
  <div class="container-xxl position-relative px-lg-5" :class="isLoading && 'd-none'">
    <div class="row pt-3 py-lg-5 mt-lg-0">
      <div class="col-12 d-none d-lg-block">
        <img src="@/assets/images/logos/logo.svg" alt="" />
      </div>
      <div class="col-12 d-flex justify-content-center d-lg-none">
        <img src="@/assets/images/logos/logo_mobile.svg" alt="" />
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-md-3">

      <div class="col-12 col-lg-8">
        <h1 class="text-center d-none d-lg-block text-primary fs-1 fw-bold">
          Entra in Easycode
        </h1>
        <h1 class="text-center d-block d-lg-none text-primary signup-header-title">
          Entra in Easycode
        </h1>
      </div>
      <div id="" class="row d-flex justify-content-center my-5">
        <div class="col-12 col-lg-8 px-4 px-lg-0">
          <FormSignUp @form-signup-loading="(event) => isLoading = event"></FormSignUp>
        </div>
        <p class="text-center m-0 px-4 signup-header-p mt-5">
          Hai già un account?
          <a class="switch-button pb-1" @click="goToLogin">
            <span>ACCEDI</span>
          </a>
          <!-- <button class="btn btn-confirm switch-button" @click="goToLogin">Accedi</button> -->
        </p>
      </div>
    </div>
  </div>

  <LoadingViewNoRedirect v-if="isLoading"/>

</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

import FormSignUp from '@/views/auth/FormSignUp.vue'
import LoadingViewNoRedirect from "@/components/LoadingViewNoRedirect.vue";

const isLoading = ref(false)

const goToLogin = () => router.push({ name: "login" });

</script>

<style lang="scss" scoped>

.switch-button {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  margin-left: 1rem;
  color: $platform-text-color-2;
  align-self: flex-end;
  cursor: pointer;


  border-bottom: 3px $platform-bg solid;
}

.signup-header-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.01em;
}

.signup-header-p {
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #242424;
}

.button-helper-cards {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  margin-left: 1rem;
  color: $platform-text-color-2;
  align-self: flex-end;
  cursor: pointer;


  border-bottom: 3px $platform-bg solid;
}
</style>
