<template>
  <div class="dashboard-start dashboard-rounded col-12 px-3 px-md-4 py-5 mt-5">
    <div class="row justify-content-center px-3">
      <div class="col-12 d-flex justify-content-between align-items-center p-0">
        <div class="d-flex align-items-center">
          <img :src="getIcon()" alt="Dashboard component image" />
          <h2 class="fs-2 fw-semibold text-primary ms-3 mb-0">{{ title }}</h2>
        </div>
        <router-link
          v-if="viewAll"
          :to="'/' + type"
          class="d-none d-md-flex"
          :class="[userInfoStore.getRole == 'primary-student' ? 'dashboard-start-link-primary' : 'dashboard-start-link']"
          >{{ userInfoStore.getRole == 'primary-student' ? 'Guarda Tutti' : 'Vedi Tutti' }}</router-link
        >
      </div>

      <Loader v-if="loading" />
      <slot v-else />

      <!-- div
        class="col-12 d-flex justify-content-center mt-5 d-md-none"
        v-if="viewAll"
      >
        <router-link :to="'/' + type" class="dashboard-start-link"
          >GUARDA TUTTI</router-link
        >
      </div -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Loader from "../Loader.vue";
import { useUserInfoStore } from "@/store";

export default defineComponent({
  name: "DashboardCmpWrapper",
  components: {
    Loader,
  },
  props: {
    loading: Boolean,
    viewAll: {
      type: Boolean,
      default: true,
    },
    type: String,
    title: String,
  },
  setup(props) {
    const userInfoStore = useUserInfoStore();

    const getIcon = () => {
      if (props.type == "class") {
        return require("@/assets/images/icons/x56/Studenti.svg");
      } else if (props.type == "missions") {
        return require("@/assets/images/icons/x56/Missions-primary.svg");
      } else if (props.type == "courses") {
        return require("@/assets/images/icons/x56/Courses-primary.svg");
      } else {
        return require("@/assets/images/icons/x56/Courses-primary.svg");
      }
    };

    return {
      getIcon,
      userInfoStore,
    };
  },
});
</script>

<style>
.dashboard-start-link-primary {
  border-radius: 50px;
  padding: 18px 24px 18px 24px;
  border: 3px solid rgba(60, 232, 218, 1);
  box-shadow: 0px 8px 16px 0px rgba(123, 135, 148, 0.08);
  box-shadow: 0px 1px 8px 0px rgba(123, 135, 148, 0.12);

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: rgba(65, 45, 205, 1);
  text-transform: uppercase;
}

</style>
