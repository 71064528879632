<template>
  <router-link class="navbar-brand" :to="{ name: 'dashboard' }">
    <img
      src="@/assets/images/logos/logo-navbar.svg"
      alt="Logo"
      class="align-text-top img-fluid"
    />
  </router-link>
  <div class="row w-50 gx-2">
    <div class="col d-flex justify-content-center align-items-center">
      <router-link
        :to="{ name: 'dashboard' }"
        class="nav-link"
        :class="path == 'dashboard' ? 'active' : ''"
        ><img
          v-if="path == 'dashboard'"
          class="img-fluid"
          src="@/assets/images/icons/x56/Dashboard-active.svg"
          alt="Dashboard"
        />
        <img
          v-else
          class="img-fluid"
          src="@/assets/images/icons/x56/Dashboard.svg"
          alt="Dashboard"
        />
        <h2 class="nav-link-title">DASHBOARD</h2>
      </router-link>
    </div>
    <div class="col d-flex justify-content-center align-items-center">
      <router-link
        :to="{ name: 'courses' }"
        class="nav-link"
        :class="
          path == 'courses' || path == 'course-page' || path == 'lesson-page'
            ? 'active'
            : ''
        "
        ><img
          v-if="
            path == 'courses' || path == 'course-page' || path == 'lesson-page'
          "
          class="img-fluid"
          src="@/assets/images/icons/x56/Courses-active.svg"
          alt="Courses"
        />
        <img
          v-else
          class="img-fluid"
          src="@/assets/images/icons/x56/Courses.svg"
          alt="Courses"
        />
        <h2 class="nav-link-title">CORSI</h2>
      </router-link>
    </div>
    <div class="col d-flex justify-content-center align-items-center">
      <router-link
        :to="{ name: 'class' }"
        class="nav-link"
        :class="path == 'class' ? 'active' : ''"
        ><img
          v-if="path == 'class'"
          class="img-fluid"
          src="@/assets/images/icons/x56/Class-active.svg"
          alt="Class"
        />
        <img
          v-else
          class="img-fluid"
          src="@/assets/images/icons/x56/Class.svg"
          alt="Class"
        />
        <h2 class="nav-link-title">CLASSE</h2>
      </router-link>
    </div>
    <div class="col d-flex justify-content-center align-items-center">
      <router-link
        :to="{ name: 'missions' }"
        class="nav-link"
        :class="path == 'missions' ? 'active' : ''"
        ><img
          v-if="path == 'missions'"
          class="img-fluid"
          src="@/assets/images/icons/x56/Missions-active.svg"
          alt="Missions"
        />
        <img
          v-else
          class="img-fluid"
          src="@/assets/images/icons/x56/Missions.svg"
          alt="Missions"
        />
        <h2 class="nav-link-title">MISSIONI</h2>
      </router-link>
    </div>
    <div class="col d-flex justify-content-center align-items-center">
      <router-link
        :to="{ name: 'notifications' }"
        class="nav-link position-relative"
        :class="path == 'notifications' ? 'active' : ''"
        ><img
          v-if="path == 'notifications'"
          class="img-fluid"
          src="@/assets/images/icons/x56/Notifications-active.svg"
          alt="Notifications"
        />
        <img
          v-else
          class="img-fluid"
          src="@/assets/images/icons/x56/Notifications.svg"
          alt="Notifications"
        />
        <div
          v-if="layoutStore.getNotifications.length > 0"
          class="notifications-counter"
        >
          <span>{{ layoutStore.getNotifications.length }}</span>
        </div>
        <h2 class="nav-link-title">NOTIFICHE</h2>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { useLayoutStore } from "@/store";
import { defineComponent, computed } from "vue";

import { useRoute } from "vue-router";

export default defineComponent({
  name: "NavbarPrimary",
  setup() {
    const layoutStore = useLayoutStore();

    const route = useRoute();
    const path = computed(() => route.name);

    return {
      path,
      layoutStore,
    };
  },
});
</script>

<style lang="scss">
.notifications-counter {
  position: absolute;
  top: -5px;
  right: 10px;
  background-color: #ea5e48;
  color: white;
  font-size: 14px;
  font-weight: 600;
  width: 24px;
  height: 24px;
  line-height: 16.8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $platform-body-bg;
}

.nav-link:hover {
  color: #cbd2d9;
}

.nav-link.active {
  color: $platform-text-color-2;
}

.nav-link-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: inherit;
  text-align: center;
  margin-top: 5px;
}
</style>
