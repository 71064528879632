<template>
  <div id="aside" class="position-fixed">
    <div class="container-fluid">
      <div class="d-flex justify-content-center mt-4">
        <router-link :to="{ name: 'dashboard' }">
          <img src="@/assets/images/logos/logo.svg" alt="" />
        </router-link>
      </div>
      <div class="mt-5">
        <ul class="list-group list-group-flush">
          <router-link
            :to="{ name: 'landing' }"
            class="list-group-item mt-2"
            :class="path == 'landing' ? 'active' : ''"
            ><img
              v-if="path == 'landing'"
              class="img-fluid"
              src="@/assets/images/icons/x32/Dashboard-active.svg"
              alt="Dashboard"
            />
            <img
              v-else
              class="img-fluid"
              src="@/assets/images/icons/x32/Dashboard.svg"
              alt="Dashboard"
            />
            <h2 class="list-group-item-title">Dashboard</h2>
          </router-link>
          <router-link
            :to="{ name: 'courses' }"
            class="list-group-item mt-2"
            :class="path == 'courses' || path == 'course-page' ? 'active' : ''"
            ><img
              v-if="path == 'courses' || path == 'course-page'"
              class="img-fluid"
              src="@/assets/images/icons/x32/Courses-active.svg"
              alt="Courses"
            />
            <img
              v-else
              class="img-fluid"
              src="@/assets/images/icons/x32/Corsi.svg"
              alt="Courses"
            />
            <h2 class="list-group-item-title">Corsi</h2>
          </router-link>
          <!-- <router-link
            :to="{ name: 'missions' }"
            class="list-group-item mt-2"
            :class="path == 'missions' ? 'active' : ''"
            ><img
              v-if="path == 'missions'"
              class="img-fluid"
              src="@/assets/images/icons/x32/Progress-active.svg"
              alt="Missions"
            />
            <img
              v-else
              class="img-fluid"
              src="@/assets/images/icons/x32/Progress.svg"
              alt="Missions"
            />
            <h2 class="list-group-item-title">Missioni</h2>
          </router-link>
          <router-link
            :to="{ name: 'class' }"
            class="list-group-item mt-2"
            :class="path == 'class' ? 'active' : ''"
            ><img
              v-if="path == 'class'"
              class="img-fluid"
              src="@/assets/images/icons/x32/Studenti-active.svg"
              alt="Class"
            />
            <img
              v-else
              class="img-fluid"
              src="@/assets/images/icons/x32/Studenti.svg"
              alt="Class"
            />
            <h2 class="list-group-item-title">Classe</h2>
          </router-link> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Aside",
  setup() {
    const route = useRoute();
    const path = computed(() => route.name);

    return {
      path,
    };
  },
});
</script>

<style lang="scss" scoped>
#aside {
  width: 250px;
  top: 0px;
  left: 0px;
  box-shadow: 0px 0px 4px rgba(123, 135, 148, 0.08),
    0px 2px 4px rgba(123, 135, 148, 0.1);
  height: 100vh;
}

.list-group-item {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 120%;
  color: #9aa5b1;
  padding: 12px 24px;
  gap: 16px;
  display: flex;
  flex: start;
  align-items: center !important;
}

.list-group-item.active {
  color: $platform-text-color-2;
  background: $platform-bg;
  border-radius: 30px;
}

.list-group-item-title {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 120%;
  color: inherit;
  margin: 0px;
}
</style>
