<template>
  <component :is="'script'">
    (function(d, w, c) {
    w.ChatraID = '6cLAs35f45WRTnzKx';
    var s = d.createElement('script');
    w[c] = w[c] || function() {
    (w[c].q = w[c].q || []).push(arguments);
    };
    s.async = true;
    s.src = 'https://call.chatra.io/chatra.js';
    if (d.head) d.head.appendChild(s);
    })(document, window, 'Chatra');
  </component>
  <div v-if="!loading && !registered" id="layout" class="container-xxl p-0"
    :class="role != 'primary-student' ? 'container-aside' : ''">
    <div class="row m-0 d-none d-lg-block">
      <Aside v-if="role != 'primary-student'" />
      <div id="content-container p-0" class="container-fluid p-0">
        <div class="row px-5 m-0" :class="role != 'primary-student' ? 'aside-margin' : ''">
          <Header :elementari="role == 'primary-student'" />
          <router-view />
        </div>
      </div>
    </div>
    <div class="row m-0 d-lg-none" style="z-index: 1">
      <HeaderMobile />
      <div class="content-wrapper-mobile p-0">
        <router-view />
      </div>
      <Footer />
    </div>
  </div>
  <div v-if="!loading && registered" id="layout" class="container-xxl p-0"
    :class="role != 'primary-student' ? 'container-aside' : ''">
    <div class="row m-0 d-none d-lg-block">
      <PublicAside v-if="role != 'primary-student'" />
      <div id="content-container p-0" class="container-fluid p-0">
        <div class="row px-5 m-0" :class="role != 'primary-student' ? 'aside-margin' : ''">
          <PublicHeader :elementari="role == 'primary-student'" :avatarLetters="avatarLetters"/>
          <router-view />
        </div>
      </div>
    </div>
    <div class="row m-0 d-lg-none" style="z-index: 1">
      <PublicHeaderMobile :avatarLetters="avatarLetters"/>
      <div class="content-wrapper-mobile p-0">
        <router-view />
      </div>
      <PublicFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, nextTick, onUnmounted, computed } from "vue";

import { useLayoutStore, useUserInfoStore } from "@/store";

import Header from "@/components/header/Header.vue";
import HeaderMobile from "@/components/header/HeaderMobile.vue";
import Aside from "@/components/aside/Aside.vue";
import Footer from "@/components/footer/Footer.vue";

import PublicHeader from "@/components/header/PublicHeader.vue"
import PublicHeaderMobile from "@/components/header/PublicHeaderMobile.vue";
import PublicAside from "@/components/aside/PublicAside.vue"
import PublicFooter from "@/components/footer/PublicFooter.vue";

import router from "@/router";

export default defineComponent({
  name: "Layout",
  components: {
    Header,
    HeaderMobile,
    Aside,
    Footer,
    PublicHeader,
    PublicHeaderMobile,
    PublicAside,
    PublicFooter,
  },
  setup() {
    const loading = ref(true);
    const width = ref(window.innerWidth);

    const role = ref("");

    const store = useUserInfoStore();
    const registered = computed(() => store.isUserRegistered)

    onMounted(() => {
      loadData();

      nextTick(() => {
        window.addEventListener("resize", () => {
          width.value = window.innerWidth;
        });
      });
    });

    onUnmounted(() => {
      window.removeEventListener("resize", () => {
        width.value = window.innerWidth;
      });
    });

    const avatarLetters = ref('')

    const loadData = async () => {

      const layoutStore = await useLayoutStore();
      const userStore = await useUserInfoStore();

      await userStore.verifyAuth();

      const userInfo = userStore.currentUserInfo;
      const user = userStore.currentUser;
      const registered = userStore.isUserRegistered
      avatarLetters.value =  (userStore.userInfo?.user?.name ? userStore.userInfo?.user?.name.charAt(0).toUpperCase() : '')
    + (userStore.userInfo?.user?.surname ? userStore.userInfo?.user?.surname.charAt(0).toUpperCase() : '')

      if (registered) {
        role.value = userInfo.role;
        await layoutStore.getUserNotifications();
        loading.value = false;

        return
      }

      if (
        userInfo.must_change_password ||
        user.nickname == null ||
        user.avatar == null
      ) {
        router.push("/start");
      }

      role.value = userInfo.role;
      await layoutStore.getUserNotifications();
      loading.value = false;
    };


    return {
      loading,
      width,
      role,
      registered,
      avatarLetters
    };
  },
});
</script>

<style lang="scss">
.navbar-margin {
  margin-top: 80px;
}

.aside-margin {
  margin-left: 250px !important;
}

.content-wrapper-mobile {
  margin-top: 100px;
  margin-bottom: 88px;
  background: white;
}

.container-aside {
  max-width: 1640px !important;
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1440px;
  }
}
</style>
