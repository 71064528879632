<template>
  <DashboardCmpWrapper :loading="loading" title="I tuoi corsi" type="courses">
    <div class="col-12 p-0 mt-3">
      <div class="container-fluid dashboard-card-wrapper">
        <div class="row flex-nowrap flex-lg-wrap mt-3">
          <template v-if="role == 'high-student' && width > 992">
            <div v-for="course in courses" :key="course.id" class="col-12">
              <CourseCardHigh :course="course" />
            </div>
          </template>
          <template v-else>
            <div
              v-for="course in courses"
              :key="course.id"
              class="col-9 col-sm-5 col-md-5 col-lg-3 d-flex"
            >
              <CourseCard :course="course" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </DashboardCmpWrapper>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

import DashboardCmpWrapper from "@/components/wrapper/DashboardCmpWrapper.vue";

import Course from "@/core/data/course";
import CourseCard from "@/components/courses/CourseCard.vue";
import CourseCardHigh from "../courses/CourseCardHigh.vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    DashboardCmpWrapper,
    CourseCard,
    CourseCardHigh,
  },
  props: {
    role: String,
    loading: Boolean,
    courses: Array as PropType<Course[]>,
  },
  setup() {

    const width = window.innerWidth;

    return {
      width,
    };
  },
});
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .dashboard-card-wrapper {
    overflow-x: scroll;
  }
}

::-webkit-scrollbar {
  display: none;
}

.text-underline {
  border-bottom: 3px solid var(--bs-blue);
}
</style>
