<template>
  <div id="footer-sm" class="container-fluid position-fixed bottom-0 py-2">
    <div class="row align-items-center h-100">
      <div
        class="col d-flex justify-content-center align-items-center p-2 h-100"
      >
        <router-link
          to="/landing"
          class="footer-item mx-4"
          :class="path == 'landing' ? 'active' : ''"
          ><img
            v-if="path == 'landing'"
            class="img-fluid"
            src="@/assets/images/icons/x32/Dashboard-active.svg"
            alt="Dashboard"
          />
          <img
            v-else
            class="img-fluid"
            src="@/assets/images/icons/x32/Dashboard.svg"
            alt="Dashboard"
          />
        </router-link>
        <router-link
          to="/courses"
          class="footer-item mx-4"
          :class="path == 'courses' || path == 'course-page' || path == 'lesson-page' ? 'active' : ''"
          ><img
            v-if="path == 'courses' || path == 'course-page' || path == 'lesson-page'"
            class="img-fluid"
            src="@/assets/images/icons/x32/Courses-active.svg"
            alt="Courses"
          />
          <img
            v-else
            class="img-fluid"
            src="@/assets/images/icons/x32/Corsi.svg"
            alt="Courses"
          />
        </router-link>
      </div>
      <!-- <div class="col d-flex justify-content-center p-2">
        <router-link
          to="/courses"
          class="footer-item"
          :class="path == 'courses' || path == 'course-page' || path == 'lesson-page' ? 'active' : ''"
          ><img
            v-if="path == 'courses' || path == 'course-page' || path == 'lesson-page'"
            class="img-fluid"
            src="@/assets/images/icons/x32/Courses-active.svg"
            alt="Courses"
          />
          <img
            v-else
            class="img-fluid"
            src="@/assets/images/icons/x32/Corsi.svg"
            alt="Courses"
          />
        </router-link>
      </div> -->
      <!-- <div class="col d-flex justify-content-center p-2">
        <router-link
          to="/missions"
          class="footer-item"
          :class="path == 'missions' ? 'active' : ''"
          ><img
            v-if="path == 'missions'"
            class="img-fluid"
            src="@/assets/images/icons/x32/Progress-active.svg"
            alt="Missions"
          />
          <img
            v-else
            class="img-fluid"
            src="@/assets/images/icons/x32/Progress.svg"
            alt="Missions"
          />
        </router-link>
      </div>
      <div
        class="col d-flex justify-content-center align-items-center p-2 h-100"
      >
        <router-link
          to="/class"
          class="footer-item"
          :class="path == 'class' ? 'active' : ''"
          ><img
            v-if="path == 'class'"
            class="img-fluid"
            src="@/assets/images/icons/x32/Studenti-active.svg"
            alt="Class"
          />
          <img
            v-else
            class="img-fluid"
            src="@/assets/images/icons/x32/Studenti.svg"
            alt="Class"
          />
        </router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "Footer",
  setup() {
    const route = useRoute();
    const path = computed(() => route.name);

    return {
      path,
    };
  },
};
</script>

<style lang="scss">
#footer-sm {
  box-shadow: 0px 1px 8px rgba(123, 135, 148, 0.12),
    0px 8px 16px rgba(123, 135, 148, 0.08);
  background-color: white;
  z-index: 999;
}

.footer-item {
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 30px;
}

.footer-item.active {
  background: $platform-bg;
}
</style>
