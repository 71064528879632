<template>
  <nav
    class="navbar position-sticky top-0 px-5"
    :class="[
      !elementari || currentRoute == 'dashboard' ? 'border-nav' : '',
    ]"
  >
    <div class="col-12 py-1 py-lg-0 px-1 px-md-0">
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <NavbarPrimary v-if="elementari" />
          <div
            class="dropdown d-flex align-items-center"
            :class="!elementari ? 'ms-auto' : ''"
          >
            <div v-if="!elementari" class="position-relative">
              <router-link :to="{ name: 'notifications' }" class="me-3"
                ><img src="@/assets/images/icons/x32/Notifiche-fill.svg" alt=""
              /></router-link>
              <div
                v-if="layoutStore.getNotifications.length > 0"
                class="notifications-counter"
              ></div>
            </div>

            <button
              class="dropdown-toggle btn d-flex align-items-center"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div v-if="!elementari" class="d-flex align-items-center ms-1">
                <UserAvatar
                  class="svg-container-sm"
                  :avatar="userStore.currentUser?.avatar?.base"
                  :part1="userStore.currentUser?.avatar?.parts.part1"
                  :part2="userStore.currentUser?.avatar?.parts.part2"
                  :part3="userStore.currentUser?.avatar?.parts.part3"
                  :part4="userStore.currentUser?.avatar?.parts.part4"
                  :part5="userStore.currentUser?.avatar?.parts.part5"
                />
                <h2 class="student-name ms-2">
                  {{ userStore.currentUser?.nickname ?? (userStore.currentUser?.name ?? "") }}
                </h2>
              </div>
              <div v-else class="ms-1">
                <UserAvatar
                  class="svg-container-lg"
                  :avatar="userStore.currentUser?.avatar?.base"
                  :part1="userStore.currentUser?.avatar?.parts.part1"
                  :part2="userStore.currentUser?.avatar?.parts.part2"
                  :part3="userStore.currentUser?.avatar?.parts.part3"
                  :part4="userStore.currentUser?.avatar?.parts.part4"
                  :part5="userStore.currentUser?.avatar?.parts.part5"
                />
              </div>
            </button>

            <HeaderDropdown />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { useLayoutStore, useUserInfoStore } from "@/store";

import UserAvatar from "../profile/UserAvatar.vue";

import NavbarPrimary from "./NavbarPrimary.vue";

import HeaderDropdown from "@/components/header/HeaderDropdown.vue";

import { useRoute } from "vue-router";

export default defineComponent({
  name: "Header",
  components: {
    NavbarPrimary,
    HeaderDropdown,
    UserAvatar,
  },
  props: {
    elementari: Boolean,
  },
  setup(props) {
    const layoutStore = useLayoutStore();
    const userStore = useUserInfoStore();

    const route = useRoute();

    const currentRoute = computed(() => route.name);

    const logout = () => {
      userStore.logout();
    };

    return {
      props,
      layoutStore,
      userStore,
      currentRoute,
      logout,
    };
  },
});
</script>

<style lang="scss" scoped>
.navbar {
  background-color: #fcfcfc;
  z-index: 999;
}

.dropdown-toggle.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border-color: white !important;
}
.notifications-counter {
  position: absolute;
  top: -5px;
  right: 15px;
  background-color: #ea5e48;
  color: white;
  font-size: 8px;
  font-weight: 600;
  width: 16px;
  height: 16px;
  line-height: 16.8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.student-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: var(--bs-blue);
}
</style>
