import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/Crown-Lesson-Yellow.svg'


const _hoisted_1 = { class: "dashboard-course-card d-flex flex-column justify-content-between flex-grow-1 align-items-center position-relative h-100" }
const _hoisted_2 = { class: "image-course-card" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "lesson-videos-crowns d-none d-lg-flex align-items-center" }
const _hoisted_5 = { class: "video-counter ms-1 px-1 py-1" }
const _hoisted_6 = { class: "d-flex flex-column flex-grow-1 justify-content-between align-items-center p-3 py-4" }
const _hoisted_7 = { class: "course-card-title fw-semibold" }
const _hoisted_8 = { class: "course-card-p fw-semibold mt-3 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imageURI + _ctx.props.course?.s3_thumbnail_name,
        class: "course-image",
        alt: ""
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        class: "crown-video-counter img-fluid mb-1",
        alt: ""
      }, null, -1 /* HOISTED */)),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.props.course?.video_count_completed + "/" + _ctx.props.course?.video_count), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.props.course?.name ?? ""), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.props.course?.description ?? ""), 1 /* TEXT */),
      _createVNode(_component_router_link, {
        to: '/course/' + _ctx.props.course?.id,
        class: "w-100 dashboard-btn"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" VAI AL CORSO ")
        ])),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])
    ])
  ]))
}