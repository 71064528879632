import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logos/logo.svg'
import _imports_1 from '@/assets/images/icons/Back.svg'
import _imports_2 from '@/assets/images/logos/logo45x45.svg'


const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 d-none d-lg-flex justify-content-end position-relative" }
const _hoisted_3 = { class: "col-12 d-flex justify-content-center d-lg-none" }
const _hoisted_4 = { class: "row d-flex flex-column align-items-center justify-content-center mt-5" }
const _hoisted_5 = { class: "col-10 col-lg-8" }
const _hoisted_6 = { class: "title text-center text-primary my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditAvatar = _resolveComponent("EditAvatar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1 /* HOISTED */)),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('returnBack'))),
          class: "btn turn-back d-flex align-items-center px-3"
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("img", {
            src: _imports_1,
            class: "me-2",
            alt: ""
          }, null, -1 /* HOISTED */),
          _createElementVNode("span", { class: "turn-back-text" }, "INDIETRO", -1 /* HOISTED */)
        ]))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("img", {
          src: _imports_2,
          alt: ""
        }, null, -1 /* HOISTED */)),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('returnBack'))),
          class: "btn turn-back d-flex align-items-center"
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1 /* HOISTED */)
        ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h1", _hoisted_6, _toDisplayString("Ottima scelta " + (_ctx.user.nickname ?? _ctx.user.name) + "!"), 1 /* TEXT */),
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "subtitle text-center m-0 px-4" }, " Adesso personalizza il tuo avatar: clicca sui vestiti, sulle parti del corpo e sul colore che preferisci. ", -1 /* HOISTED */))
      ]),
      _createVNode(_component_EditAvatar, { startAvatar: _ctx.avatar }, null, 8 /* PROPS */, ["startAvatar"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}