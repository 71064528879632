import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/logos/logo-navbar.svg'
import _imports_1 from '@/assets/images/icons/x56/Dashboard-active.svg'
import _imports_2 from '@/assets/images/icons/x56/Dashboard.svg'
import _imports_3 from '@/assets/images/icons/x56/Courses-active.svg'
import _imports_4 from '@/assets/images/icons/x56/Courses.svg'


const _hoisted_1 = { class: "row gx-2" }
const _hoisted_2 = { class: "col d-flex justify-content-center align-items-center mx-4" }
const _hoisted_3 = {
  key: 0,
  class: "img-fluid",
  src: _imports_1,
  alt: "Dashboard"
}
const _hoisted_4 = {
  key: 1,
  class: "img-fluid",
  src: _imports_2,
  alt: "Dashboard"
}
const _hoisted_5 = { class: "col d-flex justify-content-center align-items-center mx-4" }
const _hoisted_6 = {
  key: 0,
  class: "img-fluid",
  src: _imports_3,
  alt: "Courses"
}
const _hoisted_7 = {
  key: 1,
  class: "img-fluid",
  src: _imports_4,
  alt: "Courses"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      class: "navbar-brand",
      to: { name: 'landing' }
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Logo",
          class: "align-text-top img-fluid"
        }, null, -1 /* HOISTED */)
      ])),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: { name: 'landing' },
          class: _normalizeClass(["nav-link", _ctx.path == 'landing' ? 'active' : ''])
        }, {
          default: _withCtx(() => [
            (_ctx.path == 'landing')
              ? (_openBlock(), _createElementBlock("img", _hoisted_3))
              : (_openBlock(), _createElementBlock("img", _hoisted_4)),
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "nav-link-title" }, "DASHBOARD", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: { name: 'courses' },
          class: _normalizeClass(["nav-link", 
          _ctx.path == 'courses' || _ctx.path == 'course-page' || _ctx.path == 'lesson-page'
            ? 'active'
            : ''
        ])
        }, {
          default: _withCtx(() => [
            (
            _ctx.path == 'courses' || _ctx.path == 'course-page' || _ctx.path == 'lesson-page'
          )
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : (_openBlock(), _createElementBlock("img", _hoisted_7)),
            _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "nav-link-title" }, "CORSI", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ]),
      _createCommentVNode(" <div class=\"col d-flex justify-content-center align-items-center\">\n      <router-link\n        :to=\"{ name: 'class' }\"\n        class=\"nav-link\"\n        :class=\"path == 'class' ? 'active' : ''\"\n        ><img\n          v-if=\"path == 'class'\"\n          class=\"img-fluid\"\n          src=\"@/assets/images/icons/x56/Class-active.svg\"\n          alt=\"Class\"\n        />\n        <img\n          v-else\n          class=\"img-fluid\"\n          src=\"@/assets/images/icons/x56/Class.svg\"\n          alt=\"Class\"\n        />\n        <h2 class=\"nav-link-title\">CLASSE</h2>\n      </router-link>\n    </div> "),
      _createCommentVNode(" <div class=\"col d-flex justify-content-center align-items-center\">\n      <router-link\n        :to=\"{ name: 'missions' }\"\n        class=\"nav-link\"\n        :class=\"path == 'missions' ? 'active' : ''\"\n        ><img\n          v-if=\"path == 'missions'\"\n          class=\"img-fluid\"\n          src=\"@/assets/images/icons/x56/Missions-active.svg\"\n          alt=\"Missions\"\n        />\n        <img\n          v-else\n          class=\"img-fluid\"\n          src=\"@/assets/images/icons/x56/Missions.svg\"\n          alt=\"Missions\"\n        />\n        <h2 class=\"nav-link-title\">MISSIONI</h2>\n      </router-link>\n    </div> "),
      _createCommentVNode(" <div class=\"col d-flex justify-content-center align-items-center\">\n      <router-link\n        :to=\"{ name: 'notifications' }\"\n        class=\"nav-link position-relative\"\n        :class=\"path == 'notifications' ? 'active' : ''\"\n        ><img\n          v-if=\"path == 'notifications'\"\n          class=\"img-fluid\"\n          src=\"@/assets/images/icons/x56/Notifications-active.svg\"\n          alt=\"Notifications\"\n        />\n        <img\n          v-else\n          class=\"img-fluid\"\n          src=\"@/assets/images/icons/x56/Notifications.svg\"\n          alt=\"Notifications\"\n        />\n        <div\n          v-if=\"layoutStore.getNotifications.length > 0\"\n          class=\"notifications-counter\"\n        >\n          <span>{{ layoutStore.getNotifications.length }}</span>\n        </div>\n        <h2 class=\"nav-link-title\">NOTIFICHE</h2>\n      </router-link>\n    </div> ")
    ])
  ], 64 /* STABLE_FRAGMENT */))
}