<template>
  <form @submit.prevent="postChangePassword" class="form-signup">


    <div class="mb-3 position-relative">
      <label for="InputEmail1" class="form-label ms-3">Email</label>
      <input type="email" v-model="email" required class="form-control px-5" :class="[
        email != '' ? 'active' : '',
        error && error?.message ? 'is-invalid' : '',
      ]" id="InputEmail1" placeholder="Inserisci la mail" aria-describedby="emailHelp" />
      <img class="position-absolute" style="top: 45px; left: 20px" src="@/assets/images/icons/Mail.svg" alt="" />
    </div>




    <button ref="submitButton" type="submit" class="form-submit-button btn w-100 mt-4" :disabled="isButtonDisabled">
      INVIA MAIL
    </button>
  </form>

  <GenericModal
    text1="Ops, qualcosa è andato storto"
    text2="Purtroppo la mail non è stata inviata. Verifica che l'indirizzo di posta sia corretto e riprova a inviarla."
    button_text="TORNA INDIETRO"
    ref="modal"
    icon="error"
  />
  <GenericModal
    text1="Controlla la tua mail!"
    text2="Ti abbiamo inviato una mail con una password provvisoria. Appena effettuato il login ti verrà chiesto di cambiare la password provvisoria con una nuova più sicura."
    button_text="TORNA AL LOGIN"
    ref="modalConfirmChangePassword"
    icon="success"
    @modal-close="proceed"
  />

  <!-- <GenericModal :text1="'Attenzione!'" :text2="'I dati inseriti non sono corretti. Controlla e riprova'"
    :button_text="'OK, ho capito'" ref="modal" />
  <GenericModal :text1="'Registrazione completata'"
    :text2="`Hai completato la registrazione ${name.toUpperCase()}, prosegui alla selezione dei corsi`"
    :button_text="'Prosegui'" ref="modalConfirmChangePassword" @modal-close="proceed" /> -->
</template>

<script setup>

import { ref, computed, defineEmits } from 'vue';
import { useUserInfoStore } from "@/store";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";

const router = useRouter();

const store = useUserInfoStore();

import GenericModal from '@/components/modals/newModal/GenericModal.vue'
import DatePickerMask from '@/components/datepicker/DatepickerMask.vue'

const emit = defineEmits(['form-forgot-password-loading'])


const email = ref("");

const modal = ref()
const modalConfirmChangePassword = ref()

const isEmailValid = computed(() => {
  // regex validazione dell'email
  if (email.value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.value);
  } else {
    return false
  }

});

// const test = () => modalConfirmChangePassword.value.openModal()

const isButtonDisabled = computed(() => !isEmailValid.value)

// const test = () => modal.value.openModal()

const postChangePassword = async () => {

  emit('form-forgot-password-loading', true)

  const payload = {
    data: {
      user: {
        email: email.value,
      }
    }
  }

  await ApiService.post("/api/reset-password-email", payload)
    .then(async ({ data }) => {
      if (data) {
        // let result = await store.login(email.value, false);
        emit('form-forgot-password-loading', false)
        modalConfirmChangePassword.value.openModal()
      }
    })
    .catch(({ response }) => {
      console.log(response)
      emit('form-forgot-password-loading', false)
      modal.value.openModal()
    })
}

const proceed = () => router.push({ name: 'role-select' })
const error = computed(() => store.getErrors);

</script>


<style lang="scss" scoped>
.form-signup {
  max-width: 75%;
  margin-inline: auto;

  @media screen and (max-width: 992px) {
    max-width: 100%;
  }
}

.subtitle {
  align-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  color: #242424;
}

.login-header-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.01em;
}

.login-header-p {
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #242424;
}

.form-control {
  border: 1px solid var(--bs-blue) !important;
}

.form-control.active {
  border: 3px solid $platform-bg !important;
  border-radius: 49px !important;
}

.form-control:focus-visible {
  border: 3px solid $platform-bg !important;
  box-shadow: 0px 0px 0px 4px rgba(3, 40, 142, 0.3) !important;
  border-radius: 49px !important;
}

.form-control.is-invalid {
  border: 3px solid #ff5252 !important;
  box-shadow: 0px 0px 0px 4px #e8533a !important;
  border-radius: 49px !important;
}

.form-submit-button {
  color: $platform-text-color-2;
  background-color: $platform-bg;
  border-radius: 50px;
  padding: 20px 24px 20px 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
}

.form-submit-button:disabled {
  background-color: #e4e7eb;
  color: #9aa5b1;
}

.login-error-wrapper {
  background: #fde3e4;
  border-radius: 0px 5px 5px 0px;
  border-left: 3px solid #ff5252 !important;
  border-radius: 5px 5px 5px 5px;
}

.login-error-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #1f2933;
}

.privacy-text {
  font-size: 12px;
  text-justify: inter-word;
}

.accept-newsletter {
  font-size: 14px;
  text-justify: inter-word;
}

select.form-select {
  padding-left: 1rem !important;
}



.dp__outer_menu_wrap.dp--menu-wrapper {
  // @apply !shadow-xl;
}

/* modals */
.modal-layover {
  // @apply overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full bg-black/[.4] h-full grid justify-items-center lg:p-5;
}
</style>

<style lang="css">
.dp__input {
  background-color: transparent !important;
  border: none !important;
}

.dp__input_icons {
  height: 24px !important;
  width: 24px !important;
  left: -5px !important;

}

.dp__input_icon_pad {
  padding-left: 2.3rem !important;
}
</style>
