import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logos/Logo.png'


const _hoisted_1 = { class: "navbar-mobile position-fixed top-0 mb-3 px-4" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = { class: "row d-flex justify-content-between align-items-center" }
const _hoisted_4 = { class: "col p-0" }
const _hoisted_5 = { class: "col d-flex align-items-center justify-content-end p-0" }
const _hoisted_6 = { class: "dropdown d-flex align-items-center" }
const _hoisted_7 = {
  class: "dropdown-toggle btn d-flex align-items-center px-0",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_8 = { class: "col-12 col-lg-1" }
const _hoisted_9 = { class: "avatar-circle mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HeaderDropdown = _resolveComponent("HeaderDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("img", {
                src: _imports_0,
                class: "img-fluid",
                alt: ""
              }, null, -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createCommentVNode(" <div class=\"position-relative\">\n            <router-link :to=\"{ name: 'notifications' }\" class=\"me-3 position-relative\"><img\n                src=\"@/assets/images/icons/x32/Notifiche-fill.svg\" alt=\"\" />\n              <div v-if=\"layoutStore.getNotifications.length > 0\" class=\"notifications-counter\"></div>\n            </router-link>\n          </div> "),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: {name: 'role-select'},
              class: "course-header-high-link pb-1"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Cambia grado scolastico")
              ])),
              _: 1 /* STABLE */
            }),
            _createElementVNode("button", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.avatarLetters), 1 /* TEXT */)
              ])
            ]),
            _createVNode(_component_HeaderDropdown)
          ])
        ])
      ])
    ])
  ]))
}