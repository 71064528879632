<template>
  <div class="container" v-if="!loadingView">

    <!-- title section -->
    <div class="row row-eq-height card-container px-3 px-md-4 py-5 mt-5 gap-4 gap-lg-0">

      <div class="col-12 col-lg-1">
        <div class="avatar-circle mx-auto">
          {{ avatarLetters }}
        </div>
      </div>
      <div class="col-12 col-lg-11">

        <h1 class="fs-2 fw-semibold text-primary mb-0 pb-4">
          Ti do il benvenuto in Easycode, {{ currentName }}!
        </h1>
        <h2 class="card-container-subtitle fw-semibold">
          Scegli quali corsi guardare
        </h2>
        <p class="course-card-p fw-semibold mt-3">
          Hai a disposizione i corsi dedicati agli studenti della scuola primaria, della scuola secondaria di primo
          grado
          o della scuola secondaria di secondo grado. Potrai modificare la tua scelta in qualsiasi momento all’interno
          della piattaforma.
        </p>
      </div>
    </div>

    <!-- role selection -->
    <div class="row row-eq-height card-container px-3 px-md-4 py-5 mt-5">
      <div class="col-12">
        <h1 class="fs-2 fw-semibold text-primary ms-3 mb-0 py-4">
          <img src="@/assets/images/icons/x56/Courses-primary.svg" alt="" /> Seleziona i corsi di una piattaforma
        </h1>
      </div>

      <div class="col-md-4 mb-4" v-for="card in cards" :key="card.title">
        <div class="card h-100">
          <div class="card-img-container">
            <img :src="getRoleImage(card.image)" class="card-img-top" :alt="card.title">
            <!-- <img :src="card.image" class="card-img-top" :alt="card.title"> -->
          </div>
          <div class="card-body-role d-flex flex-column justify-content-between">
            <div>
              <h2 class="course-card-title fw-semibold">
                {{ card.title }}
              </h2>
              <p class="course-card-p mt-3">
                {{ card.description }}
              </p>
            </div>
            <button class="dashboard-btn" @click="selectRole(card.role)">
              <span>VAI AI CORSI</span> <img src="@/assets/images/icons/x24/Avanti.svg" alt="" class="button-arrow" />
            </button>
          </div>
        </div>
      </div>

    </div>

    <!-- helper section -->
    <div class="row row-eq-height card-container px-3 px-md-4 py-5 mt-5">
      <div class="col-12">
        <h1 class="fs-2 fw-semibold text-primary ms-3 mb-0 py-4">
          <img src="@/assets/images/icons/FloppyHelp.png" alt="" class="floppy-circle" /> Ciao, sono Floppy! Se non sai
          da
          quali corsi iniziare, chiedi aiuto a me!
        </h1>
      </div>

      <div class="col-md-4 mb-4" v-for="helpCard, index in helpCards" :key="index">
        <div class="card h-100">

          <div class="card-body-role d-flex flex-column justify-content-between">
            <div>
              <p class="course-card-p mt-3" v-html="helpCard.text">

              </p>
            </div>

            <a class="button-helper-cards pb-1" @click="selectRole(helpCard.role)">
              <span>{{ helpCard.button }}</span> <img src="@/assets/images/icons/x24/Avanti.svg" alt=""
                class="button-arrow" />
            </a>

          </div>
        </div>
      </div>

    </div>

    <!-- Upcoming courses section -->
    <div class="row row-eq-height card-container px-3 px-md-4 py-5 my-5">
      <div class="col-12">
        <h1 class="fs-2 fw-semibold text-primary ms-3 mb-0 py-4">
          <img src="@/assets/images/icons/IncomingCourses.svg" alt="" /> Corsi in arrivo sulla nostra piattaforma
        </h1>
      </div>

      <div class="col-12">
        <div class="d-flex flex-wrap justify-content-between">
          <div v-for="course in upcomingCourses" :key="course.title" class="upcoming-course-card mb-4">
            <div class="upcoming-course-image">
              <img :src="getCourseImage(course.image)" :alt="course.title">
            </div>
            <div class="upcoming-course-info">
              <h3 class="upcoming-course-title">{{ course.title }}</h3>
              <p class="upcoming-course-description">{{ course.description }}</p>
              <button class="upcoming-course-btn">{{ course.badgeText }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LoadingViewNoRedirect v-if="loadingView" />
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';

import ApiService from '@/core/services/ApiService';
import { useUserInfoStore } from "@/store";
import router from "@/router";

import LoadingViewNoRedirect from "@/components/LoadingViewNoRedirect.vue";
import PaginationComponent from "@/components/roleSelect/PaginationComponent.vue"

const store = useUserInfoStore();

const helpCards = [
  {
    text: 'Sei curioso/a, ami scoprire cose nuove e risolvere piccoli problemi?<br> <b>Scuola primaria</b> è la sezione che fa per te!',
    button: 'Scuola primaria',
    role: 'primary-student'
  },
  {
    text: 'Ti piace metterti alla prova, risolvere problemi complessi e lavorare in squadra? <b>Scuola secondaria I grado</b> è la tua area!',
    button: 'Scuola secondaria I grado',
    role: 'secondary-student'
  },
  {
    text: 'Ti appassiona il mondo della tecnologia e vuoi sviluppare competenze tecniche più avanzate? <br> Allora scegli <b>Scuola secondaria II grado!</b>',
    button: 'Scuola secondaria II grado',
    role: 'high-student'
  }
]

const cards = [
  {
    title: 'Scuola primaria',
    description: 'Impara a programmare in modo semplice e divertente, grazie a Scratch e ai kit Mbot e Lego We Do.',
    image: 'role_select_primary.jpg',
    role: 'primary-student'
  },
  {
    title: 'Scuola secondaria I grado',
    description: 'Crea la tua prima app e impara le basi dell’elettronica programmabile.',
    image: 'role_select_secondary.png',
    role: 'secondary-student'
  },
  {
    title: 'Scuola secondaria II grado',
    description: 'Esplora i principali linguaggi IT con i nostri corsi, perfetti per chi muove i primi passi nel mondo della programmazione.',
    image: 'role_select_high.jpg',
    role: 'high-student'
  }
]

const upcomingCourses = [
  {
    title: 'JavaScript',
    description: 'Scopri tutto sul linguaggio di programmazione JavaScript partendo dalle basi.',
    image: 'upcoming_js.png',
    badgeText: 'Scuola secondaria II grado'
  },
  {
    title: 'UX/UI Design',
    description: "Impara a migliorare l'esperienza utente e a progettare interfacce grafiche efficaci e intuitive.",
    image: 'upcoming_ux-ui.jpg',
    badgeText: 'Scuola secondaria II grado'
  },
  {
    title: 'Python',
    description: 'Imparerai a gestire loop e controlli di flusso per realizzare progetti pratici e divertenti.',
    image: 'upcoming_python.png',
    badgeText: 'Scuola secondaria II grado'
  }
];

const loadingView = ref(false);

// Computed che raccoglie tutte le informazioni necessarie dallo store in un unico oggetto
const userInfo = computed(() => ({
  role: store.currentUserInfo.role,
  id: store.currentUserInfo.id,
  name: store.userInfo.user.name,
  avatarLetters:
    (store.userInfo?.user?.name ? store.userInfo?.user?.name.charAt(0).toUpperCase() : '')
    + (store.userInfo?.user?.surname ? store.userInfo?.user?.surname.charAt(0).toUpperCase() : '')
}));

// Estrazione dei valori
const currentRole = computed(() => userInfo.value.role);
const currentId = computed(() => userInfo.value.id);
const currentName = computed(() => userInfo.value.name);
const avatarLetters = computed(() => userInfo.value.avatarLetters);


const getRoleImage = (img) => require(`@/assets/images/role_select/${img}`);
const getCourseImage = (img) => require(`@/assets/images/upcoming_courses/${img}`)

// const getIcon = () => {
//   if (props.type == "class") {
//     return require("@/assets/images/icons/x56/Studenti.svg");
//   } else if (props.type == "missions") {
//     return require("@/assets/images/icons/x56/Missions-primary.svg");
//   } else if (props.type == "courses") {
//     return require("@/assets/images/icons/x56/Courses-primary.svg");
//   } else {
//     return require("@/assets/images/icons/x56/Courses-primary.svg");
//   }
// };

const selectRole = async (role) => {

  if (currentRole.value !== role) {
    loadingView.value = true
    console.log('API CALL')

    const payload = {
      data: { role: role }
    }

    try {
      const response = await ApiService.post(`/api/update-user-role/${currentId.value}`, payload);
      console.log('Risposta alla POST:', response.data)
      if (response.data.status === 'success') {
        await store.verifyAuth();
        router.push({ name: "landing" })
      }
      // loader false
    } catch (error) {
      console.error('Errore nella richiesta POST:', error)
      // loader false
    } finally {
      loadingView.value = false
    }
  } else {
    router.push({ name: "landing" })
  }

};

onMounted(() => console.log(store.currentUser))

</script>

<style lang="scss" scoped>
.button-arrow {
  transform: rotate(180deg);
  margin-left: .5rem;
}

.avatar-circle {
  width: 80px;
  height: 80px;
  background-color: #EBF0FF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.2rem;
  color: #242424;
}

.card-container {
  background: #fcfcfc;
  box-shadow: 0px 8px 16px 0px rgba(123, 135, 148, 0.0784313725);
  box-shadow: 0px 1px 8px 0px rgba(123, 135, 148, 0.1215686275);
  border-radius: 32px;
}

.card-container-subtitle {
  color: #1f2933;
  font-size: 1.5rem;
}

.card {
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow: 0px 8px 16px 0px #7b879414;
  box-shadow: 0px 1px 8px 0px #7b87941f;
  border-radius: 20px;
}

.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}

.card-img-container {
  height: 200px;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card-img-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.card-body-role {
  padding: 1.5rem;
  min-height: 280px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.card-body-role.no-fixed-height {
  min-height: auto;
}

.btn-primary {
  background-color: #13294b;
  border-color: #13294b;
}

.btn-primary:hover {
  background-color: #0d1e3a;
  border-color: #0d1e3a;
}

.dashboard-course-card {
  min-width: 225px;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow: 0px 8px 16px 0px #7b879414;
  box-shadow: 0px 1px 8px 0px #7b87941f;
  border-radius: 20px;
}

.course-card-title {
  color: #1f2933;
  font-size: 1.5rem;
  text-transform: capitalize;
}


.course-card-p {
  font-size: 1rem;
  // font-weight: semibold;
  // color: #616e7c;
}

.dashboard-btn {
  display: inline-flex;
  background: $platform-bg;
  color: $platform-text-color-2;
  box-shadow: 0px 1px 8px rgba(123, 135, 148, 0.12),
    0px 8px 16px rgba(123, 135, 148, 0.08);
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 24px 20px 24px;
}

.upcoming-course-card {
  display: flex;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  border-radius: 20px;
  overflow: hidden;
  width: calc(33.333% - 16px);
  height: 200px; // Altezza della card, regolabile secondo necessità
  box-shadow: 0px 1px 8px 0px rgba(123, 135, 148, 0.1215686275);
}

.upcoming-course-image {
  width: 33.333%; // L'immagine occupa 1/3 della larghezza della card
  height: 100%; // Altezza completa della card

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.upcoming-course-info {
  width: 66.666%; // Il contenuto occupa 2/3 della larghezza della card
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upcoming-course-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1f2933;
  margin-bottom: 12px;
}

.upcoming-course-description {
  font-size: 1rem;
  // color: #616e7c;
  flex-grow: 1;
}

.upcoming-course-btn {
  background-color: #EBF0FF;
  color: #4A6CF7;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 0.8rem;
  font-weight: 600;
  align-self: flex-start;
}

@media (max-width: 1200px) {
  .upcoming-course-card {
    width: calc(50% - 16px);
  }
}

@media (max-width: 768px) {
  .upcoming-course-card {
    width: 100%;
  }
}

.floppy-circle {
  width: 80px;
  height: 80px;
}

.button-helper-cards {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  margin-right: 1rem;
  color: $platform-text-color-2;
  align-self: flex-end;
  cursor: pointer;


  border-bottom: 3px $platform-bg solid;
}
</style>
