<template>
  <div id="home" class="container-fluid px-lg-5 py-5 pt-lg-4">
    <div class="row">
      <div class="col-12 d-none d-lg-flex justify-content-end">
        <img src="@/assets/images/logos/logo.svg" alt="" />
      </div>
      <div class="col-12 d-flex justify-content-center d-lg-none">
        <img src="@/assets/images/logos/logo45x45.svg" alt="" />
      </div>
    </div>
    <div class="row d-flex flex-column align-items-center justify-content-center mt-lg-5 pt-lg-5">
      <div class="col-10 col-lg-8">
        <h1 class="title text-center text-primary my-4">
          Chi è il giocatore di Easycode?
        </h1>
        <p class="subtitle text-center m-0 px-4">
          Per iniziare, scegli un nickname.
        </p>
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-center mt-3">
        <img width="250px" src="@/assets/images/avatars/Avatar.png" alt="" />
      </div>
      <div class="col-12 col-lg-6 px-4 px-lg-0">
        <form>
          <div class="mb-3 position-relative">
            <label for="studentName" class="form-label ms-3">Nickname</label>
            <input
              type="text"
              v-model="name"
              class="form-control px-5"
              id="studentName"
              required
              placeholder="Inserisci il nome"
              aria-describedby="studentNameHelp"
            />
            <img
              class="position-absolute"
              style="top: 45px; left: 20px"
              src="@/assets/images/icons/Studente.svg"
              alt=""
            />
          </div>
          <button
            type="button"
            @click="startWelcome()"
            class="form-submit-button btn btn-platform w-100 mt-4"
            :disabled="name == ''"
          >
            CONFERMA
          </button>
        </form>
      </div>
    </div>
    <CreatePasswordModal1 />
    <CreatePasswordModal2 />
    <CreatePasswordModal3 />
    <WelcomeModal1 :name="name" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

import CreatePasswordModal1 from "@/components/modals/start/CreatePasswordModal1.vue";
import CreatePasswordModal2 from "@/components/modals/start/CreatePasswordModal2.vue";
import CreatePasswordModal3 from "@/components/modals/start/CreatePasswordModal3.vue";
import WelcomeModal1 from "@/components/modals/start/WelcomeModal1.vue";

import { useUserInfoStore } from "@/store";
import ApiService from "@/core/services/ApiService";
import router from "@/router";

export default defineComponent({
  name: "HomeView",
  components: {
    CreatePasswordModal1,
    CreatePasswordModal2,
    CreatePasswordModal3,
    WelcomeModal1,
  },
  props: {
    start: Boolean,
  },
  setup() {
    console.log("START VIEW");
    const userStore = useUserInfoStore();

    const loadData = async () => {
      // loading.value = true;
      const store = await useUserInfoStore();

      console.log('ciccia', store)

      await store
        .verifyAuth()
        .then(() => {
          const userInfo = store.currentUserInfo;
          const user = store.currentUser;
          if (
            !userInfo.must_change_password &&
            user.nickname != null &&
            user.avatar != null
          ) {
            router.push("/");
          } else {
            const userInfo = userStore.currentUserInfo;

            if (userInfo.must_change_password) {
              document
                .querySelector<HTMLButtonElement>("#CreatePasswordModal")
                ?.click();
            }
            //     start.value = true;
            //     loading.value = false;
          }
        })
      //.catch(() => {
      // loading.value = false;
      // });
    };

    onMounted(() => {
      loadData();
      //if (props.start) {
      //    const userInfo = userStore.currentUserInfo;
      //
      //    if (userInfo.must_change_password) {
      //        document
      //            .querySelector<HTMLButtonElement>("#CreatePasswordModal")
      //            ?.click();
      //    }
      //}
    });

    const name = ref("");

    const startWelcome = async () => {
      const data = {
        data: {
          profile: {
            nickname: name.value,
          },
        },
      };

      await ApiService.patch("/api/profile", data).then(() => {

        const user = userStore.currentUser

        // load updated data after the call
        loadData();

        if (user.avatar) {
          router.push("/")
        } else {
          document
            .querySelector<HTMLButtonElement>("#WelcomeModal1Button")
            ?.click();
        }

      });
    };

    return {
      startWelcome,
      name,
    };
  },
});
</script>

<style lang="scss" scoped>
.form-control {
  background: #ffffff;
  border: 1px solid #cbd2d9;
  box-shadow: 0px 0px 4px rgba(123, 135, 148, 0.08),
    0px 2px 4px rgba(123, 135, 148, 0.1);
  border-radius: 49px;
}

.title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.019em;
}

.subtitle {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 120%;
  color: #242424;
}
</style>
