import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/logos/Logo.png'
import _imports_1 from '@/assets/images/icons/x32/Notifiche-fill.svg'


const _hoisted_1 = { class: "navbar-mobile position-fixed top-0 mb-3 px-4" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = { class: "row d-flex justify-content-between align-items-center" }
const _hoisted_4 = { class: "col p-0" }
const _hoisted_5 = { class: "col d-flex align-items-center justify-content-end p-0" }
const _hoisted_6 = { class: "position-relative" }
const _hoisted_7 = {
  key: 0,
  class: "notifications-counter"
}
const _hoisted_8 = { class: "dropdown d-flex align-items-center" }
const _hoisted_9 = {
  class: "dropdown-toggle btn d-flex align-items-center px-0",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_HeaderDropdown = _resolveComponent("HeaderDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("img", {
                src: _imports_0,
                class: "img-fluid",
                alt: ""
              }, null, -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: { name: 'notifications' },
              class: "me-3 position-relative"
            }, {
              default: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("img", {
                  src: _imports_1,
                  alt: ""
                }, null, -1 /* HOISTED */)),
                (_ctx.layoutStore.getNotifications.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", _hoisted_9, [
              _createVNode(_component_UserAvatar, {
                class: "svg-container-sm",
                avatar: _ctx.userStore.currentUser?.avatar?.base,
                part1: _ctx.userStore.currentUser?.avatar?.parts.part1,
                part2: _ctx.userStore.currentUser?.avatar?.parts.part2,
                part3: _ctx.userStore.currentUser?.avatar?.parts.part3,
                part4: _ctx.userStore.currentUser?.avatar?.parts.part4,
                part5: _ctx.userStore.currentUser?.avatar?.parts.part5
              }, null, 8 /* PROPS */, ["avatar", "part1", "part2", "part3", "part4", "part5"])
            ]),
            _createVNode(_component_HeaderDropdown)
          ])
        ])
      ])
    ])
  ]))
}