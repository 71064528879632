import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logos/logo-navbar.svg'


const _hoisted_1 = { class: "navbar position-sticky border-nav top-0 px-5" }
const _hoisted_2 = { class: "col-12 py-1 py-lg-0 px-1 px-md-0" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 d-flex justify-content-between" }
const _hoisted_5 = {
  class: "dropdown-toggle btn d-flex align-items-center",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_6 = { class: "col-12 col-lg-1" }
const _hoisted_7 = { class: "avatar-circle mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderDropdown = _resolveComponent("HeaderDropdown")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            src: _imports_0,
            alt: "Logo",
            class: "align-text-top img-fluid"
          }, null, -1 /* HOISTED */)),
          _createCommentVNode(" <PublicNavbarPrimary v-if=\"elementari\" /> "),
          _createElementVNode("div", {
            class: _normalizeClass(["dropdown d-flex align-items-center", !_ctx.elementari ? 'ms-auto' : ''])
          }, [
            _createCommentVNode(" <div v-if=\"!elementari\" class=\"position-relative\">\n              <router-link :to=\"{ name: 'notifications' }\" class=\"me-3\"\n                ><img src=\"@/assets/images/icons/x32/Notifiche-fill.svg\" alt=\"\"\n              /></router-link>\n              <div\n                v-if=\"layoutStore.getNotifications.length > 0\"\n                class=\"notifications-counter\"\n              ></div>\n            </div> "),
            _createElementVNode("button", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.avatarLetters), 1 /* TEXT */)
              ])
            ]),
            _createVNode(_component_HeaderDropdown)
          ], 2 /* CLASS */)
        ])
      ])
    ])
  ]))
}