<template>
  <div class="navbar-mobile position-fixed top-0 mb-3 px-4">
    <div class="col-12 p-0">
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col p-0">
          <img src="@/assets/images/logos/Logo.png" class="img-fluid" alt="" />
        </div>
        <div class="col d-flex align-items-center justify-content-end p-0">
          <div class="dropdown d-flex align-items-center">
            <button class="dropdown-toggle btn d-flex align-items-center px-0" type="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <div class="col-12 col-lg-1">
                <div class="avatar-circle mx-auto">
                  {{avatarLetters}}
                </div>
              </div>
            </button>
            <HeaderDropdown />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useLayoutStore, useUserInfoStore } from "@/store";

// import UserAvatar from "../profile/UserAvatar.vue";
import HeaderDropdown from "./HeaderDropdown.vue";

export default defineComponent({
  name: "HeaderMobile",
  components: {
    // UserAvatar,
    HeaderDropdown
  },
  props: {
    avatarLetters: String
  },
  setup(props) {
    const layoutStore = useLayoutStore();
    const userStore = useUserInfoStore();

    return {
      props,
      layoutStore,
      userStore,
    };
  },
});
</script>

<style lang="scss" scoped>
.notifications-counter {
  position: absolute;
  top: -10px;
  right: 0px;
  background-color: #ea5e48;
  color: white;
  font-size: 8px;
  font-weight: 600;
  width: 16px;
  height: 16px;
  line-height: 16.8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-mobile {
  background-color: white;
  z-index: 999 !important;
  height: 100px;
  border-bottom: 3px solid #ebf0ff;
  display: flex;
  align-items: center;
}


.dropdown-toggle.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border-color: white !important;
}

.dropdown-toggle::after {
  display: none;
}

.avatar-circle {
  width: 60px;
  height: 60px;
  background-color: #EBF0FF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1rem;
  color: #242424;
}
</style>
