<template>
  <div v-if="!loading" id="layout" class="container-xxl p-0">
    <div v-if="width >= 992" class="row m-0">
      <div id="content-container p-0" class="container-fluid p-0">
        <div class="row px-5 m-0">
          <HeaderGeneric :elementari="role == 'primary-student'" :avatarLetters="avatarLetters"/>
          <router-view />
        </div>
      </div>
    </div>
    <div v-else class="row m-0" style="z-index: 1">
      <PublicHeaderMobileGeneric :avatarLetters="avatarLetters"/>
      <div class="content-wrapper-mobile p-0">
        <router-view />
      </div>
      <!-- <PublicFooter /> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, nextTick, onUnmounted } from "vue";

import { useLayoutStore, useUserInfoStore } from "@/store";

// import Header from "@/components/header/Header.vue";
// import HeaderMobile from "@/components/header/HeaderMobile.vue";
import Aside from "@/components/aside/Aside.vue";
import HeaderGeneric from "@/components/header/HeaderGeneric.vue"
import PublicHeaderMobileGeneric from "@/components/header/PublicHeaderMobileGeneric.vue";
// import PublicFooter from "@/components/footer/PublicFooter.vue";
import router from "@/router";

export default defineComponent({
  name: "StartLayout",
  components: {
    // Header,
    HeaderGeneric,
    PublicHeaderMobileGeneric,
    // HeaderMobile,
    // Aside,
    // PublicFooter,
  },
  setup() {
    const loading = ref(true);
    const width = ref(window.innerWidth);

    const role = ref("");

    onMounted(() => {
      loadData();

      nextTick(() => {
        window.addEventListener("resize", () => {
          width.value = window.innerWidth;
        });
      });
    });

    onUnmounted(() => {
      window.removeEventListener("resize", () => {
        width.value = window.innerWidth;
      });
    });

    const avatarLetters = ref('')

    const loadData = async () => {

      const layoutStore = await useLayoutStore();
      const userStore = await useUserInfoStore();


      await userStore.verifyAuth();

      const userInfo = userStore.currentUserInfo;
      const user = userStore.currentUser;


      avatarLetters.value =  (userStore.userInfo?.user?.name ? userStore.userInfo?.user?.name.charAt(0).toUpperCase() : '')
    + (userStore.userInfo?.user?.surname ? userStore.userInfo?.user?.surname.charAt(0).toUpperCase() : '')


      role.value = userInfo.role;
      loading.value = false;

      await layoutStore.getUserNotifications();

      loading.value = false;
    };


    return {
      loading,
      width,
      role,
      avatarLetters
    };
  },
});
</script>

<style lang="scss">
.navbar-margin {
  margin-top: 80px;
}

.aside-margin {
  margin-left: 250px !important;
}

.content-wrapper-mobile {
  margin-top: 100px;
  margin-bottom: 88px;
  background: white;
}

.container-aside {
  max-width: 1640px !important;
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1440px;
  }
}
</style>
