import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted } from 'vue';
import { Modal } from 'bootstrap';

// riferimento alla modale
interface Props {
  text1?: string;
  text2?: string;
  button_text: string; // prop obbligatoria
  icon?: string;
}

// Gestione delle props

export default /*@__PURE__*/_defineComponent({
  __name: 'GenericModal',
  props: {
    text1: { type: String, required: false },
    text2: { type: String, required: false },
    button_text: { type: String, required: true },
    icon: { type: String, required: false }
  },
  emits: ['modal-close'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const modalRef = ref<HTMLElement | null>(null);

const emit = __emit;

// Definizione delle props con tipi
const props = __props;

// Funzione per aprire la modale
const openModal = () => {
  if (modalRef.value) {
    const modalInstance = new Modal(modalRef.value, {
      backdrop: 'static',  // Impedisce la chiusura quando si clicca fuori dalla modale
      keyboard: false  // Impedisce la chiusura quando si preme il tasto ESC
    });
    modalInstance.show();
  }
};

// Funzione per chiudere la modale
const closeModal = () => {
  if (modalRef.value) {
    emit('modal-close')
    const modalInstance = Modal.getInstance(modalRef.value);
    modalInstance?.hide();
  }
};

__expose({
  openModal,
  closeModal
})

onMounted(() => {
  // Esempio: apri automaticamente la modale quando il componente è montato
  // openModal();
});


const __returned__ = { modalRef, emit, props, openModal, closeModal }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})