import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "start-layout",
  class: "container-fluid bg-gradient p-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 0,
          start: _ctx.start
        }, null, 8 /* PROPS */, ["start"]))
      : _createCommentVNode("v-if", true)
  ]))
}