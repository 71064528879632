import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logos/logo_loading.svg'


const _hoisted_1 = {
  id: "loading",
  class: "container-fluid vh-100"
}
const _hoisted_2 = { class: "row d-flex justify-content-center h-100 px-3" }
const _hoisted_3 = { class: "col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-center align-items-center" }
const _hoisted_4 = {
  class: "progress mt-5 p-0 w-100",
  role: "progressbar",
  "aria-label": "Basic example",
  "aria-valuenow": "100",
  "aria-valuemin": "0",
  "aria-valuemax": "100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_0,
          class: "img-fluid w-100",
          alt: "loading image"
        }, null, -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "progress-bar",
            style: _normalizeStyle({ width: _ctx.progress + '%' })
          }, null, 4 /* STYLE */)
        ])
      ])
    ])
  ]))
}