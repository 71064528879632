<template>
  <div
    class="col-12 d-flex flex-column justify-content-center align-items-center mt-4"
  >
    <img
      class="floppy-sleep"
      src="@/assets/images/empty/floppy-sleep.svg"
      alt=""
    />
    <h3 class="fs-3 mt-4">Sveglia Floppy!</h3>
    <p class="fs-4 text-center" v-if="!registered">
      Completa una lezione e inizia a conquistare tutti i brevetti.
    </p>
    <p class="fs-4 text-center" v-else>
      Completa una lezione e inizia a imparare.
    </p>
    <router-link to="/courses" class="btn dashboard-btn py-4 px-5 mt-3">
      VAI AI CORSI
    </router-link>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import { useUserInfoStore } from '@/store';
const userStore = useUserInfoStore();

const registered = computed(() => userStore.isUserRegistered)

</script>

<style lang="scss" scoped>
.floppy-sleep {
  background: #d9d9d9;
  border-radius: 34.4498px;
}

.dashboard-btn {
  background-color: $platform-bg;
  color: $platform-text-color-1;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.5px;
  box-shadow: 0px 1px 8px rgba(123, 135, 148, 0.12),
    0px 8px 16px rgba(123, 135, 148, 0.08);
  border-radius: 50px;
}
</style>
