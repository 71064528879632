import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "container-fluid py-5" }
const _hoisted_2 = { class: "row justify-content-center justify-content-xl-evenly" }
const _hoisted_3 = { class: "col-8 col-sm-4 col-lg-auto d-flex justify-content-center align-items-center" }
const _hoisted_4 = { class: "d-flex flex-column align-items-center" }
const _hoisted_5 = { class: "img-avatar-container col-8 col-sm-4 col-lg-auto d-flex justify-content-center align-items-center" }
const _hoisted_6 = { class: "img-avatar p-lg-4" }
const _hoisted_7 = { class: "col-8 col-sm-4 col-lg-auto d-flex justify-content-center align-items-center mt-3" }
const _hoisted_8 = { class: "col-12 col-lg-auto d-flex flex-column align-items-center justify-content-start mt-5 mt-lg-0 py-3 rounded-4 parts-wrapper" }
const _hoisted_9 = { class: "p-3 divisor-sm w-100" }
const _hoisted_10 = { class: "colors-title mb-3" }
const _hoisted_11 = { class: "row g-2 gx-3" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "mt-4 divisor p-3 pe-0 w-100" }
const _hoisted_15 = { class: "row g-2 gx-3" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "d-flex justify-content-center w-100 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileAvatar = _resolveComponent("ProfileAvatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ProfileAvatar, {
                avatar: _ctx.avatar,
                part1: _ctx.part1,
                part2: _ctx.part2,
                part3: _ctx.part3,
                part4: _ctx.part4,
                part5: _ctx.part5
              }, null, 8 /* PROPS */, ["avatar", "part1", "part2", "part3", "part4", "part5"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetAvatar())),
              class: "course-header-high-link pb-1"
            }, " RESET AVATAR ")
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.getPartName), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parts, (part, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: part,
                class: "col-4 col-lg-auto"
              }, [
                _createElementVNode("button", {
                  onClick: ($event: any) => (_ctx.currentPart = 'part' + (index + 1)),
                  class: _normalizeClass(["btn avatar-piece rounded-4", _ctx.currentPart == 'part' + (index + 1) ? 'select' : ''])
                }, [
                  _createElementVNode("img", {
                    class: "img-fluid",
                    src: _ctx.getURI + part + '.svg',
                    alt: ""
                  }, null, 8 /* PROPS */, _hoisted_13)
                ], 10 /* CLASS, PROPS */, _hoisted_12)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "colors-title mb-3" }, "Colore", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
              return (_openBlock(), _createElementBlock("div", {
                key: color,
                class: "col-auto"
              }, [
                _createElementVNode("button", {
                  style: _normalizeStyle({ backgroundColor: color }),
                  onClick: ($event: any) => (_ctx.changeColor(color)),
                  class: _normalizeClass([_ctx.currentColor == color ? 'select' : '', "btn avatar-piece-color rounded-circle"])
                }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_16)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmChange())),
          class: "btn btn-save px-5 rounded-5"
        }, " HO SCELTO ")
      ])
    ])
  ]))
}