import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "svg-container rounded-circle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Primary1 = _resolveComponent("Primary1")!
  const _component_Primary2 = _resolveComponent("Primary2")!
  const _component_Primary3 = _resolveComponent("Primary3")!
  const _component_Primary4 = _resolveComponent("Primary4")!
  const _component_Primary5 = _resolveComponent("Primary5")!
  const _component_Secondary1 = _resolveComponent("Secondary1")!
  const _component_Secondary2 = _resolveComponent("Secondary2")!
  const _component_Secondary3 = _resolveComponent("Secondary3")!
  const _component_Secondary4 = _resolveComponent("Secondary4")!
  const _component_Secondary5 = _resolveComponent("Secondary5")!
  const _component_High1 = _resolveComponent("High1")!
  const _component_High2 = _resolveComponent("High2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.role == 'primary-student')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.avatar == 'robot-1')
            ? (_openBlock(), _createBlock(_component_Primary1, {
                key: 0,
                hair: _ctx.part1,
                head: _ctx.part2,
                body: _ctx.part3,
                hands: _ctx.part4,
                dot: _ctx.part5
              }, null, 8 /* PROPS */, ["hair", "head", "body", "hands", "dot"]))
            : _createCommentVNode("v-if", true),
          (_ctx.avatar == 'robot-2')
            ? (_openBlock(), _createBlock(_component_Primary2, {
                key: 1,
                hair: _ctx.part1,
                head: _ctx.part2,
                body: _ctx.part3,
                hands: _ctx.part4,
                dot: _ctx.part5
              }, null, 8 /* PROPS */, ["hair", "head", "body", "hands", "dot"]))
            : _createCommentVNode("v-if", true),
          (_ctx.avatar == 'robot-3')
            ? (_openBlock(), _createBlock(_component_Primary3, {
                key: 2,
                hair: _ctx.part1,
                head: _ctx.part2,
                body: _ctx.part3,
                hands: _ctx.part4,
                dot: _ctx.part5
              }, null, 8 /* PROPS */, ["hair", "head", "body", "hands", "dot"]))
            : _createCommentVNode("v-if", true),
          (_ctx.avatar == 'robot-4')
            ? (_openBlock(), _createBlock(_component_Primary4, {
                key: 3,
                hair: _ctx.part1,
                head: _ctx.part2,
                body: _ctx.part3,
                hands: _ctx.part4,
                dot: _ctx.part5
              }, null, 8 /* PROPS */, ["hair", "head", "body", "hands", "dot"]))
            : _createCommentVNode("v-if", true),
          (_ctx.avatar == 'robot-5')
            ? (_openBlock(), _createBlock(_component_Primary5, {
                key: 4,
                hair: _ctx.part1,
                head: _ctx.part2,
                body: _ctx.part3,
                hands: _ctx.part4,
                dot: _ctx.part5
              }, null, 8 /* PROPS */, ["hair", "head", "body", "hands", "dot"]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : (_ctx.role == 'secondary-student')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.avatar == 'guy-green-violet')
              ? (_openBlock(), _createBlock(_component_Secondary1, {
                  key: 0,
                  mask: _ctx.part1,
                  face: _ctx.part2,
                  body: _ctx.part3,
                  hands: _ctx.part4,
                  feet: _ctx.part5
                }, null, 8 /* PROPS */, ["mask", "face", "body", "hands", "feet"]))
              : _createCommentVNode("v-if", true),
            (_ctx.avatar == 'gal-band')
              ? (_openBlock(), _createBlock(_component_Secondary2, {
                  key: 1,
                  mask: _ctx.part1,
                  face: _ctx.part2,
                  body: _ctx.part3,
                  hands: _ctx.part4,
                  feet: _ctx.part5
                }, null, 8 /* PROPS */, ["mask", "face", "body", "hands", "feet"]))
              : _createCommentVNode("v-if", true),
            (_ctx.avatar == 'guy-band')
              ? (_openBlock(), _createBlock(_component_Secondary3, {
                  key: 2,
                  mask: _ctx.part1,
                  face: _ctx.part2,
                  body: _ctx.part3,
                  hands: _ctx.part4,
                  feet: _ctx.part5
                }, null, 8 /* PROPS */, ["mask", "face", "body", "hands", "feet"]))
              : _createCommentVNode("v-if", true),
            (_ctx.avatar == 'bot-blue')
              ? (_openBlock(), _createBlock(_component_Secondary4, {
                  key: 3,
                  mask: _ctx.part1,
                  face: _ctx.part2,
                  body: _ctx.part3,
                  hands: _ctx.part4,
                  feet: _ctx.part5
                }, null, 8 /* PROPS */, ["mask", "face", "body", "hands", "feet"]))
              : _createCommentVNode("v-if", true),
            (_ctx.avatar == 'bot-salmon')
              ? (_openBlock(), _createBlock(_component_Secondary5, {
                  key: 4,
                  mask: _ctx.part1,
                  face: _ctx.part2,
                  body: _ctx.part3,
                  hands: _ctx.part4,
                  feet: _ctx.part5
                }, null, 8 /* PROPS */, ["mask", "face", "body", "hands", "feet"]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_ctx.avatar == 'boys')
              ? (_openBlock(), _createBlock(_component_High1, {
                  key: 0,
                  class: "'top-0",
                  body: _ctx.part1,
                  eyes: _ctx.part2,
                  eyeBrow: _ctx.part3,
                  hair: _ctx.part4,
                  tshirt: _ctx.part5
                }, null, 8 /* PROPS */, ["body", "eyes", "eyeBrow", "hair", "tshirt"]))
              : _createCommentVNode("v-if", true),
            (_ctx.avatar == 'girls')
              ? (_openBlock(), _createBlock(_component_High2, {
                  key: 1,
                  class: "top-0",
                  body: _ctx.part1,
                  eyes: _ctx.part2,
                  eyeBrow: _ctx.part3,
                  hair: _ctx.part4,
                  tshirt: _ctx.part5
                }, null, 8 /* PROPS */, ["body", "eyes", "eyeBrow", "hair", "tshirt"]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
  ]))
}