<template>
  <div id="loading" class="container-fluid vh-100">
    <div class="row d-flex justify-content-center h-100 px-3">
      <div class="col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-center align-items-center">
        <img src="@/assets/images/logos/logo_loading.svg" class="img-fluid w-100" alt="loading image" />
        <div class="progress mt-5 p-0 w-100" role="progressbar" aria-label="Basic example" aria-valuenow="100"
          aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar" :style="{ width: progress + '%' }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, nextTick, defineComponent } from "vue";


export default defineComponent({
  name: "LoadingViewNoRedirect",
  setup() {
    const progress = ref(0);

    onMounted(() => {
      nextTick(() => {
        var interval = setInterval(async () => {
          if (progress.value == 100) {
            clearInterval(interval);

          } else {
            progress.value += 1;
          }
        }, 25);
      });
    });

    return { progress };
  }
})



</script>

<style lang="scss" scoped>
.progress {
  height: 8px;
}

.progress-bar {
  height: 8px;
  background-color: $platform-text-color-2;
}
</style>
