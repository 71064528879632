import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/icons/x32/Notifiche-fill.svg'


const _hoisted_1 = { class: "col-12 py-1 py-lg-0 px-1 px-md-0" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 d-flex justify-content-between" }
const _hoisted_4 = {
  key: 0,
  class: "position-relative"
}
const _hoisted_5 = {
  key: 0,
  class: "notifications-counter"
}
const _hoisted_6 = {
  class: "dropdown-toggle btn d-flex align-items-center",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_7 = {
  key: 0,
  class: "d-flex align-items-center ms-1"
}
const _hoisted_8 = { class: "student-name ms-2" }
const _hoisted_9 = {
  key: 1,
  class: "ms-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarPrimary = _resolveComponent("NavbarPrimary")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_HeaderDropdown = _resolveComponent("HeaderDropdown")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["navbar position-sticky top-0 px-5", [
      !_ctx.elementari || _ctx.currentRoute == 'dashboard' ? 'border-nav' : '',
    ]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.elementari)
            ? (_openBlock(), _createBlock(_component_NavbarPrimary, { key: 0 }))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            class: _normalizeClass(["dropdown d-flex align-items-center", !_ctx.elementari ? 'ms-auto' : ''])
          }, [
            (!_ctx.elementari)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_router_link, {
                    to: { name: 'notifications' },
                    class: "me-3"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: ""
                      }, null, -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  }),
                  (_ctx.layoutStore.getNotifications.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("button", _hoisted_6, [
              (!_ctx.elementari)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_UserAvatar, {
                      class: "svg-container-sm",
                      avatar: _ctx.userStore.currentUser?.avatar?.base,
                      part1: _ctx.userStore.currentUser?.avatar?.parts.part1,
                      part2: _ctx.userStore.currentUser?.avatar?.parts.part2,
                      part3: _ctx.userStore.currentUser?.avatar?.parts.part3,
                      part4: _ctx.userStore.currentUser?.avatar?.parts.part4,
                      part5: _ctx.userStore.currentUser?.avatar?.parts.part5
                    }, null, 8 /* PROPS */, ["avatar", "part1", "part2", "part3", "part4", "part5"]),
                    _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.userStore.currentUser?.nickname ?? (_ctx.userStore.currentUser?.name ?? "")), 1 /* TEXT */)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_UserAvatar, {
                      class: "svg-container-lg",
                      avatar: _ctx.userStore.currentUser?.avatar?.base,
                      part1: _ctx.userStore.currentUser?.avatar?.parts.part1,
                      part2: _ctx.userStore.currentUser?.avatar?.parts.part2,
                      part3: _ctx.userStore.currentUser?.avatar?.parts.part3,
                      part4: _ctx.userStore.currentUser?.avatar?.parts.part4,
                      part5: _ctx.userStore.currentUser?.avatar?.parts.part5
                    }, null, 8 /* PROPS */, ["avatar", "part1", "part2", "part3", "part4", "part5"])
                  ]))
            ]),
            _createVNode(_component_HeaderDropdown)
          ], 2 /* CLASS */)
        ])
      ])
    ])
  ], 2 /* CLASS */))
}