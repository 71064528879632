import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/logos/logo-navbar.svg'
import _imports_1 from '@/assets/images/icons/x56/Dashboard-active.svg'
import _imports_2 from '@/assets/images/icons/x56/Dashboard.svg'
import _imports_3 from '@/assets/images/icons/x56/Courses-active.svg'
import _imports_4 from '@/assets/images/icons/x56/Courses.svg'
import _imports_5 from '@/assets/images/icons/x56/Class-active.svg'
import _imports_6 from '@/assets/images/icons/x56/Class.svg'
import _imports_7 from '@/assets/images/icons/x56/Missions-active.svg'
import _imports_8 from '@/assets/images/icons/x56/Missions.svg'
import _imports_9 from '@/assets/images/icons/x56/Notifications-active.svg'
import _imports_10 from '@/assets/images/icons/x56/Notifications.svg'


const _hoisted_1 = { class: "row w-50 gx-2" }
const _hoisted_2 = { class: "col d-flex justify-content-center align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "img-fluid",
  src: _imports_1,
  alt: "Dashboard"
}
const _hoisted_4 = {
  key: 1,
  class: "img-fluid",
  src: _imports_2,
  alt: "Dashboard"
}
const _hoisted_5 = { class: "col d-flex justify-content-center align-items-center" }
const _hoisted_6 = {
  key: 0,
  class: "img-fluid",
  src: _imports_3,
  alt: "Courses"
}
const _hoisted_7 = {
  key: 1,
  class: "img-fluid",
  src: _imports_4,
  alt: "Courses"
}
const _hoisted_8 = { class: "col d-flex justify-content-center align-items-center" }
const _hoisted_9 = {
  key: 0,
  class: "img-fluid",
  src: _imports_5,
  alt: "Class"
}
const _hoisted_10 = {
  key: 1,
  class: "img-fluid",
  src: _imports_6,
  alt: "Class"
}
const _hoisted_11 = { class: "col d-flex justify-content-center align-items-center" }
const _hoisted_12 = {
  key: 0,
  class: "img-fluid",
  src: _imports_7,
  alt: "Missions"
}
const _hoisted_13 = {
  key: 1,
  class: "img-fluid",
  src: _imports_8,
  alt: "Missions"
}
const _hoisted_14 = { class: "col d-flex justify-content-center align-items-center" }
const _hoisted_15 = {
  key: 0,
  class: "img-fluid",
  src: _imports_9,
  alt: "Notifications"
}
const _hoisted_16 = {
  key: 1,
  class: "img-fluid",
  src: _imports_10,
  alt: "Notifications"
}
const _hoisted_17 = {
  key: 2,
  class: "notifications-counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      class: "navbar-brand",
      to: { name: 'dashboard' }
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Logo",
          class: "align-text-top img-fluid"
        }, null, -1 /* HOISTED */)
      ])),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: { name: 'dashboard' },
          class: _normalizeClass(["nav-link", _ctx.path == 'dashboard' ? 'active' : ''])
        }, {
          default: _withCtx(() => [
            (_ctx.path == 'dashboard')
              ? (_openBlock(), _createElementBlock("img", _hoisted_3))
              : (_openBlock(), _createElementBlock("img", _hoisted_4)),
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "nav-link-title" }, "DASHBOARD", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: { name: 'courses' },
          class: _normalizeClass(["nav-link", 
          _ctx.path == 'courses' || _ctx.path == 'course-page' || _ctx.path == 'lesson-page'
            ? 'active'
            : ''
        ])
        }, {
          default: _withCtx(() => [
            (
            _ctx.path == 'courses' || _ctx.path == 'course-page' || _ctx.path == 'lesson-page'
          )
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : (_openBlock(), _createElementBlock("img", _hoisted_7)),
            _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "nav-link-title" }, "CORSI", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_router_link, {
          to: { name: 'class' },
          class: _normalizeClass(["nav-link", _ctx.path == 'class' ? 'active' : ''])
        }, {
          default: _withCtx(() => [
            (_ctx.path == 'class')
              ? (_openBlock(), _createElementBlock("img", _hoisted_9))
              : (_openBlock(), _createElementBlock("img", _hoisted_10)),
            _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "nav-link-title" }, "CLASSE", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_router_link, {
          to: { name: 'missions' },
          class: _normalizeClass(["nav-link", _ctx.path == 'missions' ? 'active' : ''])
        }, {
          default: _withCtx(() => [
            (_ctx.path == 'missions')
              ? (_openBlock(), _createElementBlock("img", _hoisted_12))
              : (_openBlock(), _createElementBlock("img", _hoisted_13)),
            _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "nav-link-title" }, "MISSIONI", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_router_link, {
          to: { name: 'notifications' },
          class: _normalizeClass(["nav-link position-relative", _ctx.path == 'notifications' ? 'active' : ''])
        }, {
          default: _withCtx(() => [
            (_ctx.path == 'notifications')
              ? (_openBlock(), _createElementBlock("img", _hoisted_15))
              : (_openBlock(), _createElementBlock("img", _hoisted_16)),
            (_ctx.layoutStore.getNotifications.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.layoutStore.getNotifications.length), 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true),
            _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "nav-link-title" }, "NOTIFICHE", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}