<template>
  <!-- Modal -->
  <div class="modal fade" id="basic-info-modal" tabindex="-1" aria-labelledby="basic-info-modal-label"
    aria-hidden="true" ref="modalRef">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="container-fluid modal-container h-100">
          <div class="row flex-column justify-content-center h-100">
            <div class="col-12 d-flex flex-column align-items-center justify-content-center">

              <img src="@/assets/images/modals/success.svg" alt="success-icon" v-if="props?.icon === 'success'">
              <img src="@/assets/images/modals/error.svg" alt="success-error"  :style="{ fill: 'red' }" v-else-if="props?.icon === 'error'">
              <img src="@/assets/images/courses/FloppyInfo.svg" alt="Floppy" v-else/>

              <p class="description">
                {{ text1 }}
              </p>
              <p class="text-center m-0 px-4">
                {{ text2 }}
              </p>
              <button type="button" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"
                class="btn-confirm btn w-100 mt-4">
                {{ button_text.toUpperCase() }}
              </button>

              <!-- <button ref="submitButton" type="submit" class="form-submit-button btn w-100 mt-4"
                :disabled="isButtonDisabled">
                {{ button_text }}
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps, defineEmits, defineExpose } from 'vue';
import { Modal } from 'bootstrap';

// riferimento alla modale
const modalRef = ref<HTMLElement | null>(null);

const emit = defineEmits(['modal-close']);

// Definizione delle props con tipi
interface Props {
  text1?: string;
  text2?: string;
  button_text: string; // prop obbligatoria
  icon?: string;
}

// Gestione delle props
const props = defineProps<Props>();

// Funzione per aprire la modale
const openModal = () => {
  if (modalRef.value) {
    const modalInstance = new Modal(modalRef.value, {
      backdrop: 'static',  // Impedisce la chiusura quando si clicca fuori dalla modale
      keyboard: false  // Impedisce la chiusura quando si preme il tasto ESC
    });
    modalInstance.show();
  }
};

// Funzione per chiudere la modale
const closeModal = () => {
  if (modalRef.value) {
    emit('modal-close')
    const modalInstance = Modal.getInstance(modalRef.value);
    modalInstance?.hide();
  }
};

defineExpose({
  openModal,
  closeModal
})

onMounted(() => {
  // Esempio: apri automaticamente la modale quando il componente è montato
  // openModal();
});

</script>
<style lang="scss" scoped>
.modal-dialog {

  @media screen and (min-width: 768px) {
    max-width: 600px;
  }
}

.modal-content {
  background-color: white;
  width: 750px;
  height: 477px;
  padding: 64px 64px 40px 64px;
  border-radius: 24px !important;
}

.description {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #242424;
}

// .btn-confirm {
//   background-color: $platform-bg;
//   color: $platform-text-color-2;
//   border-radius: 50px;
//   box-shadow: 0px 8px 16px 0px #7b879414;
//   box-shadow: 0px 1px 8px 0px #7b87941f;
// }

.btn-confirm {
  color: $platform-text-color-2;
  background-color: $platform-bg;
  border-radius: 50px;
  padding: 20px 24px 20px 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .modal-content {
    padding: 44px 64px 40px 64px;
  }

  .description {
    line-height: 28px;
  }
}
</style>
