<template>
  <div
    class="dashboard-course-card d-flex flex-column justify-content-between flex-grow-1 align-items-center position-relative h-100"
  >
    <div class="image-course-card">
      <img
        :src="imageURI + props.course?.s3_thumbnail_name"
        class="course-image"
        alt=""
      />
    </div>
    

    <div class="lesson-videos-crowns d-none d-lg-flex align-items-center">
      <img
        src="@/assets/images/icons/Crown-Lesson-Yellow.svg"
        class="crown-video-counter img-fluid mb-1"
        alt=""
      />
      <span class="video-counter ms-1 px-1 py-1">{{
        props.course?.video_count_completed + "/" + props.course?.video_count
      }}</span>
    </div>

    <div
      class="d-flex flex-column flex-grow-1 justify-content-between align-items-center p-3 py-4"
    >
      <h2 class="course-card-title fw-semibold">
        {{ props.course?.name ?? "" }}
      </h2>
      <p class="course-card-p fw-semibold mt-3 text-center">
        {{ props.course?.description ?? "" }}
      </p>

      <router-link
        :to="'/course/' + props.course?.id"
        class="w-100 dashboard-btn"
      >
        VAI AL CORSO
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import Course from "@/core/data/course";

export default defineComponent({
  name: "CourseCard",
  props: {
    course: Object as PropType<Course>,
  },
  setup(props) {
    const imageURI = process.env.VUE_APP_S3_PATH;

    return {
      props,
      imageURI,
    };
  },
});
</script>

<style lang="scss" scoped>
.dashboard-course-card {
  min-width: 225px;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow: 0px 8px 16px 0px #7b879414;
  box-shadow: 0px 1px 8px 0px #7b87941f;
  border-radius: 20px;
}

.course-card-title {
  color: #1f2933;
  font-size: 1.5rem;
  text-align: center;
  text-transform: capitalize;
}

.course-card-p {
  font-size: 1rem;
  color: #616e7c;
}

.course-image {
  height: 100%;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  max-height: 165px;
  width: 100%;
  object-fit: cover;
  filter: brightness(90%)
}
.dashboard-btn {
    display: inline-flex;
    background: $platform-bg;
    color: $platform-text-color-2;
    box-shadow: 0px 1px 8px rgba(123, 135, 148, 0.12),
      0px 8px 16px rgba(123, 135, 148, 0.08);
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px 24px 20px 24px;
}

.lesson-videos-crowns {
  position: absolute;
  top: 5%;
  left: 8%;
}
.video-counter {
  color: #e4e7eb;
  background-color: #242424;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  border-radius: 19px;
}

.crown-video-counter {
  width: 25px;
}

.lessons-count-img {
  height: 20px;
}

.image-course-card{
  height: 140px;
  width: 100%;
}
</style>
