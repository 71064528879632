import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "layout",
  class: "container-xxl p-0"
}
const _hoisted_2 = {
  key: 0,
  class: "row m-0"
}
const _hoisted_3 = {
  id: "content-container p-0",
  class: "container-fluid p-0"
}
const _hoisted_4 = { class: "row px-5 m-0" }
const _hoisted_5 = {
  key: 1,
  class: "row m-0",
  style: {"z-index":"1"}
}
const _hoisted_6 = { class: "content-wrapper-mobile p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderGeneric = _resolveComponent("HeaderGeneric")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PublicHeaderMobileGeneric = _resolveComponent("PublicHeaderMobileGeneric")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.width >= 992)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_HeaderGeneric, {
                    elementari: _ctx.role == 'primary-student',
                    avatarLetters: _ctx.avatarLetters
                  }, null, 8 /* PROPS */, ["elementari", "avatarLetters"]),
                  _createVNode(_component_router_view)
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_PublicHeaderMobileGeneric, { avatarLetters: _ctx.avatarLetters }, null, 8 /* PROPS */, ["avatarLetters"]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_router_view)
              ]),
              _createCommentVNode(" <PublicFooter /> ")
            ]))
      ]))
    : _createCommentVNode("v-if", true)
}