import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/logos/logo.svg'
import _imports_1 from '@/assets/images/icons/x32/Dashboard-active.svg'
import _imports_2 from '@/assets/images/icons/x32/Dashboard.svg'
import _imports_3 from '@/assets/images/icons/x32/Courses-active.svg'
import _imports_4 from '@/assets/images/icons/x32/Corsi.svg'


const _hoisted_1 = {
  id: "aside",
  class: "position-fixed"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "d-flex justify-content-center mt-4" }
const _hoisted_4 = { class: "mt-5" }
const _hoisted_5 = { class: "list-group list-group-flush" }
const _hoisted_6 = {
  key: 0,
  class: "img-fluid",
  src: _imports_1,
  alt: "Dashboard"
}
const _hoisted_7 = {
  key: 1,
  class: "img-fluid",
  src: _imports_2,
  alt: "Dashboard"
}
const _hoisted_8 = {
  key: 0,
  class: "img-fluid",
  src: _imports_3,
  alt: "Courses"
}
const _hoisted_9 = {
  key: 1,
  class: "img-fluid",
  src: _imports_4,
  alt: "Courses"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: { name: 'dashboard' } }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1 /* HOISTED */)
          ])),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: { name: 'landing' },
            class: _normalizeClass(["list-group-item mt-2", _ctx.path == 'landing' ? 'active' : ''])
          }, {
            default: _withCtx(() => [
              (_ctx.path == 'landing')
                ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                : (_openBlock(), _createElementBlock("img", _hoisted_7)),
              _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "list-group-item-title" }, "Dashboard", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          _createVNode(_component_router_link, {
            to: { name: 'courses' },
            class: _normalizeClass(["list-group-item mt-2", _ctx.path == 'courses' || _ctx.path == 'course-page' ? 'active' : ''])
          }, {
            default: _withCtx(() => [
              (_ctx.path == 'courses' || _ctx.path == 'course-page')
                ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                : (_openBlock(), _createElementBlock("img", _hoisted_9)),
              _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "list-group-item-title" }, "Corsi", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          _createCommentVNode(" <router-link\n            :to=\"{ name: 'missions' }\"\n            class=\"list-group-item mt-2\"\n            :class=\"path == 'missions' ? 'active' : ''\"\n            ><img\n              v-if=\"path == 'missions'\"\n              class=\"img-fluid\"\n              src=\"@/assets/images/icons/x32/Progress-active.svg\"\n              alt=\"Missions\"\n            />\n            <img\n              v-else\n              class=\"img-fluid\"\n              src=\"@/assets/images/icons/x32/Progress.svg\"\n              alt=\"Missions\"\n            />\n            <h2 class=\"list-group-item-title\">Missioni</h2>\n          </router-link>\n          <router-link\n            :to=\"{ name: 'class' }\"\n            class=\"list-group-item mt-2\"\n            :class=\"path == 'class' ? 'active' : ''\"\n            ><img\n              v-if=\"path == 'class'\"\n              class=\"img-fluid\"\n              src=\"@/assets/images/icons/x32/Studenti-active.svg\"\n              alt=\"Class\"\n            />\n            <img\n              v-else\n              class=\"img-fluid\"\n              src=\"@/assets/images/icons/x32/Studenti.svg\"\n              alt=\"Class\"\n            />\n            <h2 class=\"list-group-item-title\">Classe</h2>\n          </router-link> ")
        ])
      ])
    ])
  ]))
}