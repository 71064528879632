import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "dashboard",
  class: "col-12 p-4 mb-5 mt-lg-5"
}
const _hoisted_2 = { class: "row m-0 flex-column" }
const _hoisted_3 = { class: "col-12 col-md-10 dashboard-welcome my-4 gx-5 gx-lg-0 mx-lg-0" }
const _hoisted_4 = { class: "dashboard-welcome-title fs-1 fw-bold text-primary" }
const _hoisted_5 = { class: "dashboard-welcome-title fs-1 fw-bold text-primary" }
const _hoisted_6 = {
  key: 2,
  class: "dashboard-welcome-title fs-1 fw-bold text-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Start = _resolveComponent("Start")!
  const _component_Courses = _resolveComponent("Courses")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.currentUserInfo.role == 'secondary-student')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("h1", _hoisted_4, _toDisplayString("Ciao " +(_ctx.user?.name ?? "") + ", impariamo qualcosa?"), 1 /* TEXT */),
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "dashboard-welcome-p fs-4 fw-medium text-dark" }, " Riprendi i corsi da dove li hai lasciati. ", -1 /* HOISTED */))
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        (_ctx.currentUserInfo.role == 'high-student')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("h1", _hoisted_5, _toDisplayString("Hey " + (_ctx.user?.name ?? "") + ", sei qui!"), 1 /* TEXT */),
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "dashboard-welcome-p fs-4 fw-medium text-dark" }, " Riprendi i corsi da dove li hai lasciati! ", -1 /* HOISTED */))
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        (_ctx.currentUserInfo.role == 'primary-student')
          ? (_openBlock(), _createElementBlock("h1", _hoisted_6, _toDisplayString("Ciao " + (_ctx.user?.name ?? "") + ", continua la tua avventura!"), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      _createVNode(_component_Start, {
        loading: _ctx.loading,
        courses: _ctx.startedCourses
      }, null, 8 /* PROPS */, ["loading", "courses"]),
      _createVNode(_component_Courses, {
        role: _ctx.currentUserInfo.role,
        loading: _ctx.loading,
        courses: _ctx.yourCourses
      }, null, 8 /* PROPS */, ["role", "loading", "courses"]),
      _createCommentVNode(" <Missions  :class=\"currentUserInfo.role == 'primary-student' ? 'order-5' : ''\"/> "),
      _createCommentVNode(" <Class  :class=\"currentUserInfo.role == 'primary-student' ? 'order-4' : ''\"/> ")
    ])
  ]))
}