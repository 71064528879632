import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/icons/Eye-Crossed.svg'
import _imports_1 from '@/assets/images/icons/Eye-Active.svg'


const _hoisted_1 = {
  class: "modal fade",
  id: "CreatePasswordModal2",
  "data-bs-backdrop": "static",
  tabindex: "-1",
  "aria-labelledby": "CreatePasswordModal2Label",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-slideout modal-lg modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content m-4 m-lg-0" }
const _hoisted_4 = { class: "row p-3 p-lg-4" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = {
  key: 0,
  class: "login-error-wrapper mb-2 p-1 d-flex align-items-center"
}
const _hoisted_7 = { class: "login-error-text p-0 m-0" }
const _hoisted_8 = { class: "mt-3 mb-2 position-relative" }
const _hoisted_9 = ["type"]
const _hoisted_10 = { class: "my-3 position-relative" }
const _hoisted_11 = ["type"]
const _hoisted_12 = { class: "ps-2" }
const _hoisted_13 = { class: "list-group ps-1 ps-lg-2" }
const _hoisted_14 = { class: "row d-flex justify-content-center mt-2" }
const _hoisted_15 = { class: "col-12 col-lg-6 d-flex justify-content-center" }
const _hoisted_16 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Open Password Modal 3 "),
    _cache[13] || (_cache[13] = _createElementVNode("button", {
      id: "OpenPasswordModal3",
      type: "button",
      "data-bs-target": "#CreatePasswordModal3",
      "data-bs-toggle": "modal",
      class: "d-none"
    }, null, -1 /* HOISTED */)),
    _createCommentVNode(" Close Password Modal 2 "),
    _cache[14] || (_cache[14] = _createElementVNode("button", {
      id: "ClosePasswordModal2",
      type: "button",
      "data-bs-target": "#CreatePasswordModal2",
      "data-bs-dismiss": "modal",
      class: "d-none"
    }, null, -1 /* HOISTED */)),
    _createCommentVNode(" Modal "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "text1 mt-2" }, "Crea la tua password", -1 /* HOISTED */)),
              _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text2 my-4" }, " Inserisci una nuova password per cambiare quella provvisoria. ", -1 /* HOISTED */)),
              _createElementVNode("form", {
                onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changePassword && _ctx.changePassword(...args)), ["prevent"]))
              }, [
                (_ctx.errors && _ctx.errors?.message)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.errors?.message), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_8, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", {
                    for: "new_password",
                    class: "form-label ms-3"
                  }, "Nuova Password", -1 /* HOISTED */)),
                  _withDirectives(_createElementVNode("input", {
                    type: _ctx.show1 == false ? 'password' : 'text',
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                    class: _normalizeClass(["form-control px-4", [
                    _ctx.password != '' ? 'active' : '',
                    _ctx.errors && _ctx.errors?.message ? 'is-invalid' : '',
                  ]]),
                    id: "new_password",
                    placeholder: "Inserisci nuova password",
                    "aria-describedby": "password",
                    required: "",
                    onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.passwordChanged && _ctx.passwordChanged(...args)))
                  }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_9), [
                    [_vModelDynamic, _ctx.password]
                  ]),
                  (!_ctx.show1)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "position-absolute eye",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.show1 = !_ctx.show1)),
                        src: _imports_0,
                        alt: ""
                      }))
                    : (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: "position-absolute eye",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.show1 = !_ctx.show1)),
                        style: {"top":"45px","right":"35px","cursor":"pointer"},
                        src: _imports_1,
                        alt: ""
                      }))
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", {
                    for: "confirm_password",
                    class: "form-label ms-3"
                  }, "Conferma Password", -1 /* HOISTED */)),
                  _withDirectives(_createElementVNode("input", {
                    type: _ctx.show2 == false ? 'password' : 'text',
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.confirm_password) = $event)),
                    class: _normalizeClass(["form-control px-4", [
                    _ctx.confirm_password != '' ? 'active' : '',
                    _ctx.errors && _ctx.errors?.message ? 'is-invalid' : '',
                  ]]),
                    placeholder: "Inserisci la password",
                    id: "confirm_password",
                    "aria-describedby": "confirmPassword",
                    required: ""
                  }, null, 10 /* CLASS, PROPS */, _hoisted_11), [
                    [_vModelDynamic, _ctx.confirm_password]
                  ]),
                  (!_ctx.show2)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "position-absolute eye",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.show2 = !_ctx.show2)),
                        src: _imports_0,
                        alt: ""
                      }))
                    : (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: "position-absolute eye",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.show2 = !_ctx.show2)),
                        style: {"top":"45px","right":"35px","cursor":"pointer"},
                        src: _imports_1,
                        alt: ""
                      }))
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[10] || (_cache[10] = _createElementVNode("p", { class: "password-check mt-5" }, " La tua password deve contenere: ", -1 /* HOISTED */)),
                  _createElementVNode("ul", _hoisted_13, [
                    _createElementVNode("li", {
                      class: _normalizeClass(["list-group-item mt-2", _ctx.rulePwdMin8Chars.class()])
                    }, " Minimo 8 caratteri ", 2 /* CLASS */),
                    _createCommentVNode(" li class=\"list-group-item mt-2\">\n                    <img\n                      src=\"@/assets/images/icons/Path.svg\"\n                      class=\"me-2\"\n                      alt=\"\"\n                    />Almeno una lettera maiuscola\n                  </li>\n                  <li class=\"list-group-item mt-2\">\n                    <img\n                      src=\"@/assets/images/icons/Path.svg\"\n                      class=\"me-2\"\n                      alt=\"\"\n                    />Almeno un numero\n                  </li>\n                  <li class=\"list-group-item mt-2\">\n                    <img\n                      src=\"@/assets/images/icons/Path.svg\"\n                      class=\"me-1\"\n                      alt=\"\"\n                    />\n                    Almeno un carattere speciale (es. $ ! &)\n                  </li ")
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("button", {
                      type: "submit",
                      class: "form-submit-button btn btn-platform mt-4 w-100",
                      disabled: _ctx.password == '' || _ctx.confirm_password == ''
                    }, " CONFERMA ", 8 /* PROPS */, _hoisted_16)
                  ])
                ])
              ], 32 /* NEED_HYDRATION */)
            ])
          ])
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}