import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/images/icons/crown.svg'
import _imports_1 from '@/assets/images/icons/Crown-Lesson-Medium-Yellow.svg'


const _hoisted_1 = { class: "d-flex align-items-end mt-4 w-100" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  class: "img-fluid crown-large",
  alt: ""
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  class: "img-fluid crown",
  alt: ""
}
const _hoisted_4 = { class: "progress w-100 ms-1" }
const _hoisted_5 = {
  key: 2,
  class: "points ms-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.large)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : (_openBlock(), _createElementBlock("img", _hoisted_3)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "progress-bar",
        style: _normalizeStyle({ width: _ctx.percentage + '%' }),
        role: "progressbar",
        "aria-label": "Basic example",
        "aria-valuenow": "75",
        "aria-valuemin": "0",
        "aria-valuemax": "100"
      }, null, 4 /* STYLE */)
    ]),
    (_ctx.points)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " " + _toDisplayString(_ctx.points), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}