<template>
  <DashboardCmpWrapper
    :loading="false"
    :title="title"
    type="courses"
    :viewAll="false"
    :class="{ 'start-bg': courses?.length && courses?.length > 0 }"
  >
    <EmptyStart v-if="courses?.length == 0 || loading" />
    <CourseStart v-else :courses="courses" />
  </DashboardCmpWrapper>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from "vue";

import Course from "@/core/data/course";

import DashboardCmpWrapper from "@/components/wrapper/DashboardCmpWrapper.vue";
import EmptyStart from "@/components/dashbaord/start/EmptyStart.vue";
import CourseStart from "@/components/dashbaord/start/CourseStart.vue";

export default defineComponent({
  name: "Dashboard-Start",
  components: {
    DashboardCmpWrapper,
    EmptyStart,
    CourseStart,
  },
  props: {
    loading: Boolean,
    courses: Array as PropType<Course[]>,
  },
  setup(props) {
    const title = computed(() => {
      if (props.courses?.length == 0) {
        return "Inizia ad imparare";
      } else {
        return "Continua ad imparare";
      }
    });

    return {
      title,
    };
  },
});
</script>

<style lang="scss" scoped>
.start-bg {
  background: linear-gradient(
    250.83deg,
    #a4bcff -3.45%,
    #dccdff 105.93%
  ) !important;
}
</style>
